import { gql } from '@apollo/client';

export const INSERT_CLIENT_MODEL = gql`
  mutation MyMutation($configuration: jsonb, $name: String, $client_id: uuid, $description: String) {
    insert_sextant_client_model(
      objects: { configuration: $configuration, name: $name, client_id: $client_id, description: $description }
    ) {
      returning {
        client_model_id
      }
    }
  }
`;

export const ADD_GOOGLE_ANALYTICS_ACCOUNT = gql`
  mutation MyMutation($starred: Boolean = false, $name: String, $ga_account_id: String, $client_id: uuid) {
    insert_google_analytics_account_one(
      object: { client_id: $client_id, ga_account_id: $ga_account_id, name: $name, starred: $starred }
    ) {
      client_id
      created
      ga_account_id
      name
      starred
    }
  }
`;

export const ADD_GOOGLE_ANALYTICS_PROPERTY = gql`
  mutation MyMutation(
    $starred: Boolean = false
    $name: String
    $ga_property_id: String
    $client_id: uuid
    $ga_account_id: String
  ) {
    insert_google_analytics_property_one(
      object: {
        client_id: $client_id
        ga_property_id: $ga_property_id
        ga_account_id: $ga_account_id
        name: $name
        starred: $starred
      }
    ) {
      client_id
      created
      ga_property_id
      name
      starred
    }
  }
`;

export const ADD_GOOGLE_ANALYTICS_VIEW = gql`
  mutation MyMutation(
    $starred: Boolean = false
    $name: String
    $ga_view_id: String
    $client_id: uuid
    $ga_property_id: String
    $ga_account_id: String
  ) {
    insert_google_analytics_view_one(
      object: {
        client_id: $client_id
        ga_view_id: $ga_view_id
        ga_property_id: $ga_property_id
        ga_account_id: $ga_account_id
        name: $name
        starred: $starred
      }
    ) {
      client_id
      created
      ga_view_id
      ga_url
      name
      starred
    }
  }
`;

export const SET_PROFILE = gql`
  mutation SetProfile($family_name: String!, $given_name: String!) {
    update_client_user(where: {}, _set: { family_name: $family_name, given_name: $given_name }) {
      affected_rows
    }
  }
`;

export const SET_FAVORITE = gql`
  mutation SetFavorite($dashboard_id: uuid!, $favorite: Boolean!) {
    update_compass_metabase_dashboard_by_pk(
      pk_columns: { dashboard_id: $dashboard_id }
      _set: { favorite: $favorite }
    ) {
      dashboard_id
    }
  }
`;

export const UPDATE_TEST = gql`
  mutation UpdateTest(
    $test_id: uuid!
    $name: String!
    $description: String!
    $start_url: String!
    $log_bucket: String!
    $log_path: String!
    $network_capture_config: jsonb!
    $enabled: Boolean!
    $updated: timestamp!
    $cron_schedule: String!
    $test_type: String!
  ) {
    update_sonar_test_by_pk(
      pk_columns: { test_id: $test_id }
      _set: {
        name: $name
        description: $description
        start_url: $start_url
        log_bucket: $log_bucket
        log_path: $log_path
        network_capture_config: $network_capture_config
        enabled: $enabled
        cron_schedule: $cron_schedule
        test_type: $test_type
        updated: $updated
      }
    ) {
      test_id
      name
      description
      network_capture_config
      start_url
      log_bucket
      log_path
      cron_schedule
      test_type
      enabled
    }
  }
`;

export const ADD_NEW_STEP = gql`
  mutation AddNewStep($test_id: uuid!, $step_id: uuid!, $sequence: Int!, $created: timestamp!, $updated: timestamp!) {
    insert_sonar_step(
      objects: {
        test_id: $test_id
        step_id: $step_id
        name: "New Step"
        sequence: $sequence
        type: "NAV"
        action_type: "GOTO"
        created: $created
        updated: $updated
        config: "{}"
      }
    ) {
      returning {
        step_id
        name
        type
        action_type
        config
        sequence
        updated
      }
    }
  }
`;

export const ADD_NEW_ASSERTION = gql`
  mutation AddNewAssertion($test_id: uuid!, $assertion_id: uuid!, $created: timestamp!, $updated: timestamp!) {
    insert_sonar_assertion(
      objects: {
        test_id: $test_id
        assertion_id: $assertion_id
        name: "New Assertion"
        created: $created
        updated: $updated
        type: "EQUAL"
        config: "{}"
      }
    ) {
      returning {
        assertion_id
        name
        type
        config
        updated
        created
      }
    }
  }
`;

export const ADD_NEW_ESCALATION = gql`
  mutation AddNewEscalation($test_id: uuid!, $escalation_id: uuid!, $created: timestamp!, $updated: timestamp!) {
    insert_sonar_escalation(
      objects: {
        test_id: $test_id
        escalation_id: $escalation_id
        name: "New Escalation"
        created: $created
        updated: $updated
        type: "NOTIFICATION"
        config: "{}"
      }
    ) {
      returning {
        escalation_id
        name
        type
        config
        updated
        created
      }
    }
  }
`;

export const DELETE_TEST = gql`
  mutation DeleteTest($client_id: uuid = "", $test_id: uuid!) {
    delete_sonar_client_test(where: { client_id: { _eq: $client_id }, test_id: { _eq: $test_id } }) {
      affected_rows
      returning {
        client_id
      }
    }
    delete_sonar_test(where: { test_id: { _eq: $test_id } }) {
      affected_rows
      returning {
        test_id
      }
    }
  }
`;
