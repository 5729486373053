import { Message } from 'semantic-ui-react';

export const ErrorSegment = ({ message }) => {
  return (
    <Message negative>
      <Message.Header>There was an error.</Message.Header>
      <p>{message}</p>
      <p>Please contact aditya@wheelhousedmg.com if this message persists.</p>
    </Message>
  );
};
