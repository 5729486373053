import { useQuery, gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { Message, Form, Button, Select, TextArea } from 'semantic-ui-react';
import { v4 } from 'uuid';

const ADD_NEW_SERVICE_LOG = gql`
  mutation addServiceLog(
    $log_id: uuid!
    $test_id: uuid!
    $cognito_id: uuid!
    $created: timestamptz!
    $time_resolved: timestamptz!
    $impact_notes: String!
    $resolution_notes: String!
    $resolved_status: String!
    $updated: timestamptz!
    $resolved_state: Boolean!
  ) {
    insert_services_log(
      objects: {
        log_id: $log_id
        test_id: $test_id
        cognito_id: $cognito_id
        created: $created
        time_resolved: $time_resolved
        impact_notes: $impact_notes
        resolution_notes: $resolution_notes
        resolved_status: $resolved_status
        updated: $updated
      }
    ) {
      returning {
        log_id
        test_id
        impact_notes
        resolution_notes
        resolved_status
        time_resolved
        updated
        user {
          given_name
          family_name
        }
      }
    }
    update_sonar_test_by_pk(pk_columns: { test_id: $test_id }, _set: { resolved_state: $resolved_state }) {
      test_id
      name
      resolved_state
    }
  }
`;

const GET_MY_ID = gql`
  query GetMyId {
    client_user {
      cognito_id
    }
  }
`;

//Types
type ServiceLog = {
  cognito_id: string;
  impact_notes: string;
  log_id: string;
  resolution_notes: string;
  resolved_status: string;
  test_id: string;
  time_resolved?: string;
};

const resolvedStatus = [
  { key: 'fixed', text: 'Fixed', value: 'FIXED' },
  { key: 'depricated', text: 'Depricated', value: 'DEPRICATED' },
];

type Props = {
  test_id: string;
};

const ResolutionForm = ({ test_id }: Props) => {
  const [itemResolvedState, setItemResolvedState] = useState(false);
  const { loading: l, error: e, data: myid } = useQuery(GET_MY_ID);

  //Setup Test State
  const [resolvedFormState, setResolvedFormState] = useState<ServiceLog>({
    cognito_id: myid,
    impact_notes: '',
    log_id: v4(),
    resolution_notes: '',
    resolved_status: '',
    test_id,
  });

  //Add Resolution Log
  const [addResolutionLog, { loading: mutationResolutionLog, error: mutationResolutionLogError }] = useMutation(
    ADD_NEW_SERVICE_LOG,
    {
      onCompleted: (data) => {
        setItemResolvedState(true);
        // eslint-disable-next-line no-console
        console.log(data);
      },
    },
  );

  if (l) {
    return <p>Loading...</p>;
  }
  if (e) {
    return <p>Error :(</p>;
  }

  //Returns the message when we have resolved.
  if (itemResolvedState) {
    return <Message color="teal">This test has been marked resolved!</Message>;
  }

  return (
    <div>
      <Form
        onSubmit={(e) => {
          const now = new Date();
          e.preventDefault();
          addResolutionLog({
            variables: {
              cognito_id: myid.client_user[0].cognito_id,
              created: now,
              impact_notes: resolvedFormState.impact_notes,
              log_id: resolvedFormState.log_id,
              resolution_notes: resolvedFormState.resolution_notes,
              resolved_state: true,
              resolved_status: resolvedFormState.resolved_status,
              test_id,
              time_resolved: now,
              updated: now,
            },
          });
        }}
      >
        <Form.Field
          id="form-input-control-resolution-status"
          control={Select}
          label="Resolution Status"
          value={resolvedFormState.resolved_status}
          options={resolvedStatus}
          onChange={(e, { value }) =>
            setResolvedFormState({
              ...resolvedFormState,
              resolved_status: value,
            })
          }
        />
        <Form.Field
          id="form-input-control-impact-notes"
          control={TextArea}
          value={resolvedFormState.impact_notes}
          label="What was the Impact of the Failure?"
          placeholder="This impacted..."
          onChange={(e, { value }) => setResolvedFormState({ ...resolvedFormState, impact_notes: value })}
        />
        <Form.Field
          id="form-input-control-resolution-notes"
          control={TextArea}
          label="How was this Resolved?"
          value={resolvedFormState.resolution_notes}
          onChange={(e, { value }) =>
            setResolvedFormState({
              ...resolvedFormState,
              resolution_notes: value,
            })
          }
        />
        <p></p>
        <Button primary type="submit">
          Resolve Test
        </Button>
        {mutationResolutionLog && <p>Loading...</p>}
        {mutationResolutionLogError && <p>Error :( Please try again</p>}
      </Form>
    </div>
  );
};

export default ResolutionForm;
