import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Button, Table } from 'semantic-ui-react';
import { GET_CLIENTS } from '../gql/queries.gql';

//List Client Includes the Aggrigate of Current Tests
type ListClient = {
  client_client_test_aggregate: {
    aggregate: {
      count: number;
    };
  };
  client_id: string;
  description: string;
  name: string;
};

const ClientList = () => {
  const { loading, data } = useQuery(GET_CLIENTS, {
    fetchPolicy: 'cache-first',
  });

  //Use Apollo's loading feature to prevent errors
  if (loading) {
    return null;
  }

  //Mapping a ListClient type for the column data.
  //const clientListData = data.client_client.map((client: ListClient) => ({ client_id: client.client_id, name: client.name, description: client.description, test_count: client.client_client_test_aggregate.aggregate.count }));

  //console.log("CD Data");
  //console.log(clientListData);

  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Client Name</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Activity</Table.HeaderCell>
            <Table.HeaderCell>View</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data.client_client.map((client: ListClient) => (
            <Table.Row key={client.client_id} className={client.client_id}>
              <Table.Cell>
                <Link to={`/sonar/client/${client.client_id}`}>
                  <h3>{client.name}</h3>
                </Link>
              </Table.Cell>
              <Table.Cell>{client.description}</Table.Cell>
              <Table.Cell>TODO</Table.Cell>
              <Table.Cell>
                <Link to={`/sonar/client/${client.client_id}`}>
                  <Button primary>View</Button>
                </Link>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        {/* <Table.Footer>
        <Table.Row>
            <Table.HeaderCell colSpan='3'>
            <Menu floated='right' pagination>
                <Menu.Item as='a' icon>
                <Icon name='chevron left' />
                </Menu.Item>
                <Menu.Item as='a'>1</Menu.Item>
                <Menu.Item as='a'>2</Menu.Item>
                <Menu.Item as='a'>3</Menu.Item>
                <Menu.Item as='a'>4</Menu.Item>
                <Menu.Item as='a' icon>
                <Icon name='chevron right' />
                </Menu.Item>
            </Menu>
            </Table.HeaderCell>
        </Table.Row>
        </Table.Footer> */}
      </Table>
    </div>
  );
};

export default ClientList;
