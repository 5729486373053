import { useState } from 'react';
import { Grid, Header, Form, Statistic, Button, Segment } from 'semantic-ui-react';
import { modelMap } from '../../../constants';
import { interpolateColors } from '../../../utils/string';

const redGradient = interpolateColors('rgb(204, 16, 2)', 'rgb(64, 0, 0)', 100);

const ModelComparison: React.FC<{
  csv: string;
  data;
  graphStates: string[];
  transitionMatrix: string;
}> = ({ data, csv }) => {
  const [state, setState] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    heatmapData?: any;
    holdout?: {
      afterConv?: number;
      afterRows?: number;
      csv?: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      excluded?: any;
      loading?: boolean;
      show?: boolean;
    };
    model_first: boolean;
    model_last: boolean;
    model_lastNdr: boolean;
    model_linear: boolean;
    model_markov: boolean;
    model_shapley: boolean;
    model_survival: boolean;
    stacked: boolean;
  }>({
    model_first: true,
    model_last: true,
    model_lastNdr: true,
    model_linear: true,
    model_markov: true,
    model_shapley: false,
    model_survival: false,
    stacked: false,
  });
  const chartData = [];
  let channels = [];
  const tableHeaders = [];
  const parsedData = JSON.parse(data);
  for (const model in parsedData) {
    if (modelMap[model] && state[modelMap[model].key]) {
      if (!modelMap[model].label.includes('RE')) {
        tableHeaders.push(modelMap[model].label);
      }
      chartData.push({
        name: modelMap[model].label,
        ...parsedData[model],
      });
    }

    channels = [...channels, ...Object.keys(parsedData[model])];
  }
  channels = Array.from(new Set(channels)).filter((c) => c !== 'Start' && c !== 'Conversion');

  // Calculate journey metrics
  let beforeConv = 0;
  let interactions = 0;
  const rows = csv.split('\n').length;

  for (const row of csv.split('\n').slice(1)) {
    beforeConv += parseInt(row.split(',')[3]);
    interactions += row.split(',')[0].split('>').length * parseInt(row.split(',')[3]);
  }

  const beforeRows = rows;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const avgPathLength = (interactions / beforeConv).toFixed(2);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const totalInteractions = interactions;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange = (e, data) =>
    setState({
      ...state,
      [data.name]: data.value ? data.value : !state[data.name],
    });
  const handleHoldout = (value) => {
    setState({ ...state, holdout: { ...state.holdout, loading: true } });
    const excluded = state.holdout && state.holdout.excluded ? state.holdout.excluded : [];
    const idx = excluded.indexOf(value);
    let afterConv = 0;
    if (idx >= 0) {
      excluded.splice(idx);
    } else {
      excluded.push(value);
    }
    let rows = [];
    let c = 0;

    for (const row of csv.split('\n').slice(1)) {
      c = 0;
      for (const chan of excluded) {
        if (row.includes(chan)) {
          c++;
        }
      }
      if (c === 0) {
        afterConv += parseInt(row.split(',')[3]);
        rows.push(row);
      }
    }
    if (excluded.length === 0) {
      rows = csv.split('\n');
    }
    setTimeout(
      () =>
        setState({
          ...state,
          holdout: {
            afterConv,
            afterRows: rows.length,
            csv: rows.join('\n'),
            excluded,
            loading: false,
            show: true,
          },
        }),
      400,
    );
  };

  return (
    <>
      <Grid.Row>
        <Grid.Column>
          <Header as="h3">
            Hold-Out Simulation
            <Header.Subheader>
              Using the Variable Order Markov model, simulate the conversion impact of &quot;turning off&quot; a single
              channel, or combination of channels.
            </Header.Subheader>
          </Header>
          <Form>
            <Form.Group inline>
              <label>Channels to Remove</label>
              {channels.map((c) => (
                <Button
                  key={c}
                  active={state.holdout && state.holdout.excluded && state.holdout.excluded.includes(c)}
                  color={
                    state.holdout && state.holdout.excluded && state.holdout.excluded.includes(c) ? 'red' : 'green'
                  }
                  onClick={() => handleHoldout(c)}
                  content={c}
                />
              ))}
            </Form.Group>
          </Form>
          {state.holdout && state.holdout.show ? (
            <Segment loading={state.holdout.loading}>
              <Statistic.Group widths={3} style={{ width: '100%', padding: '25px 0' }} className="holdout">
                <Statistic>
                  <Statistic.Value
                    style={{
                      color: redGradient[((state.holdout.afterRows / beforeRows) * 100).toFixed(0)],
                    }}
                  >{`${Math.round((1 - state.holdout.afterConv / beforeConv) * 100)}%`}</Statistic.Value>
                  <Statistic.Label>Decrease in Conversions</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Statistic.Value
                    style={{
                      color: redGradient[((state.holdout.afterRows / beforeRows) * 100).toFixed(0)],
                    }}
                  >
                    {(beforeConv - state.holdout.afterConv).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </Statistic.Value>
                  <Statistic.Label>Lost Conversions</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Statistic.Value
                    style={{
                      color: redGradient[((state.holdout.afterRows / beforeRows) * 100).toFixed(0)],
                    }}
                  >
                    {state.holdout.afterRows.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </Statistic.Value>
                  <Statistic.Label>Remaining Journeys</Statistic.Label>
                </Statistic>
              </Statistic.Group>
            </Segment>
          ) : null}
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export default ModelComparison;
