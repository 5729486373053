import { useQuery, gql } from '@apollo/client';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import ClientList from './Client/ClientList';

const GET_ACTIVE_CLIENT = gql`
  query GetActiveClient {
    client_user {
      active_client_id
    }
  }
`;

export const Sonar = () => {
  const [client_id] = useState('');
  const { loading: l, data: current } = useQuery(GET_ACTIVE_CLIENT, {
    fetchPolicy: 'no-cache',
  });

  if (l) {
    return null;
  }
  const redirectString = `/sonar/client/${current.client_user[0].active_client_id}`;
  return (
    <div>
      <Redirect to={redirectString} />
      <div
        className="sonar"
        style={{
          width: '100%',
          padding: '20px',
          borderBottom: '1px solid #ddd',
        }}
      >
        {!client_id ? <ClientList /> : null}
      </div>
    </div>
  );
};
