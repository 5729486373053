import { gql } from '@apollo/client';

export const GET_CURRENT_CLIENT = gql`
  query GetCurrentClient {
    client_user {
      active_client_id
    }
  }
`;

export const GET_CLIENT_MODELS = gql`
  query GetClientModels {
    sextant_client_model {
      configuration
      name
      description
      client_model_id
    }
  }
`;

export const GET_MODEL = gql`
  query GetModel($client_model_id: uuid!) {
    sextant_client_model_by_pk(client_model_id: $client_model_id) {
      configuration
      description
      name
    }
  }
`;

export const GET_METABASE_DASHBOARDS = gql`
  query MyQuery {
    compass_client_folder {
      folder_id
      name
      metabase_dashboards {
        dashboard_id
        favorite
        name
      }
    }
  }
`;

export const GET_GOOGLE_ANALYTICS = gql`
  query GetGoogleAnalytics {
    google_analytics_account {
      properties {
        views {
          name
          ga_view_id
        }
        ga_property_id
        name
      }
      name
      ga_account_id
    }
  }
`;

export const GET_CURRENT_LOGO = gql`
  query GetLogo {
    client_user {
      active_client_rel {
        logo_thumb
      }
      family_name
      given_name
    }
  }
`;

export const GET_PROFILE = gql`
  query GetProfile {
    client_user(limit: 1) {
      family_name
      given_name
      cognito_email
    }
  }
`;

export const GET_METABASE_DASHBOARDS_PK = gql`
  query MyQuery($folder_id: uuid!) {
    compass_client_folder_by_pk(folder_id: $folder_id) {
      folder_id
      name
      metabase_dashboards {
        dashboard_id
        favorite
        name
      }
    }
  }
`;

export const GetMetabaseDashboards = gql`
  query GetMetabaseDashboards($dashboard_id: uuid!) {
    compass_metabase_dashboard_by_pk(dashboard_id: $dashboard_id) {
      dashboard_id
      favorite
      metabase_id
    }
  }
`;

export const GET_SERVICE_LOGS = gql`
  query ServiceLogs($client_id: uuid!, $time_resolved: timestamptz) {
    services_log(
      where: { test: { client_tests: { client_id: { _eq: $client_id } } }, time_resolved: { _gt: $time_resolved } }
    ) {
      test_id
      resolved_status
      log_id
      time_resolved
      test {
        test_type
      }
    }
  }
`;

export const GET_CLIENT_TEST = gql`
  query getClientTest($client_id: uuid!, $test_id: uuid!) {
    sonar_client_test(where: { client_id: { _eq: $client_id }, test_id: { _eq: $test_id } }) {
      client_id
      test {
        test_id
        name
        description
        enabled
        log_bucket
        log_path
        network_capture_config
        start_url
        cron_schedule
        test_type
        steps {
          step_id
          sequence
        }
        assertions {
          assertion_id
        }
        escalations {
          escalation_id
        }
      }
    }
  }
`;
