import { useQuery } from '@apollo/client';
import Axios from 'axios';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Image, Menu, Button, Grid, Header, Form, Progress, Message } from 'semantic-ui-react';
import styled from 'styled-components';
import LogoGreen from '../../../assets/images/sextant/logo_dark_green.svg';
import { ErrorSegment, LoadingSegment } from '../../../components';
import { GET_MODEL } from '../../../graphql/queries';
import { getMCF } from '../ModelBuilder/Helper';
import ModelComparison from './ModelComparison';
import ModelStats from './ModelStats';
import Refine from './Refine';
import Simulate from './Simulate';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

const StyledHeader = styled(Header)`
  line-height: 0px !important;
  font-size: 28px !important;
  margin-left: 15px !important;
`;

const StyledLoadingHeader = styled(Header)`
  width: 100% !important;
  text-align: center !important;
`;

const StripDiv = styled.div`
  padding: 15px;
  display: flex;
  margin-bottom: 25px;
`;

const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
`;

const StyledLogo = styled(Image)`
  height: 50px;
`;

type CustomParamsType = {
  modelId: string;
};

export const Modeler = () => {
  const [state, setState] = useState<{
    activeTab: string;
    endDate?: Date;
    graphStates?: string[];
    loadingStage?: {
      message: string;
      percent: number;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    modelResult?: any;
    paths?: {
      csv: string;
      json: string;
    };
    refineEndDate?: Date;
    refineStartDate?: Date;
    startDate?: Date;
    transitionMatrix?: string;
  }>({
    activeTab: 'compare',
  });
  const history = useHistory();
  const { modelId } = useParams<CustomParamsType>();
  const { loading, error, data } = useQuery(GET_MODEL, {
    variables: { client_model_id: modelId },
  });
  if (loading) {
    return <LoadingSegment />;
  } else if (error) {
    return <ErrorSegment message={JSON.stringify(error)} />;
  }
  let model;
  if (data) {
    model = data.sextant_client_model_by_pk;
  }

  const handleChange = (e, data) =>
    setState({
      ...state,
      [data.name]: data.value ? data.value : !state[data.name],
    });
  const handleCompareSubmit = async () => {
    setState({
      ...state,
      loadingStage: { percent: 25, message: 'Pulling Conversions' },
    });
    const mcf = await getMCF(
      model.configuration.gaAccount,
      model.configuration.gaProperty,
      model.configuration.gaView,
      model.configuration.conversionType,
      state.startDate.toISOString().slice(0, 10),
      state.endDate.toISOString().slice(0, 10),
      model.configuration.goalNumber || null,
    );
    if (mcf && mcf.csv) {
      setState({
        ...state,
        loadingStage: { percent: 50, message: 'Running Models' },
      });
      const requestData = {
        csv: mcf.csv,
        format: 'json',
        goal: model.configuration.goalNumber || 0,
        order: 2,
        transactionGoal: model.configuration.conversionType === 'transaction',
      };
      const res = await Axios.post('https://j4akagkb66.execute-api.us-west-2.amazonaws.com/v0/sextant/model', {
        body: requestData,
      });
      if (res && res.status === 200) {
        setState({
          ...state,
          loadingStage: { percent: 100, message: 'Crunching Numbers' },
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setState({
          ...state,
          graphStates: res.data.states.split('|'),
          loadingStage: null,
          modelResult: JSON.parse(res.data.body),
          paths: mcf,
          transitionMatrix: res.data.transition,
        });
      }
    }
  };

  const renderContent = () => {
    switch (state.activeTab) {
      case 'compare': {
        return (
          <>
            <Grid.Row>
              <Grid.Column>
                {state.loadingStage ? (
                  <>
                    <StyledLoadingHeader as="h4">{state.loadingStage.message}</StyledLoadingHeader>
                    <Progress indicating percent={state.loadingStage.percent} />
                  </>
                ) : null}
              </Grid.Column>
            </Grid.Row>
            {state.modelResult ? (
              <ModelComparison
                data={state.modelResult}
                csv={state.paths.csv}
                graphStates={state.graphStates}
                transitionMatrix={state.transitionMatrix}
              />
            ) : null}{' '}
          </>
        );
      }
      case 'refine': {
        return (
          <>
            <Grid.Row>
              <Grid.Column>
                {state.loadingStage ? (
                  <>
                    <StyledLoadingHeader as="h4">{state.loadingStage.message}</StyledLoadingHeader>
                    <Progress indicating percent={state.loadingStage.percent} />
                  </>
                ) : null}
              </Grid.Column>
            </Grid.Row>
            {state.modelResult ? (
              <Refine csv={state.paths.csv} graphStates={state.graphStates} transitionMatrix={state.transitionMatrix} />
            ) : null}{' '}
          </>
        );
      }
      case 'simulate': {
        return (
          <Simulate
            data={state.modelResult}
            csv={state.paths.csv}
            graphStates={state.graphStates}
            transitionMatrix={state.transitionMatrix}
          />
        );
      }
      default: {
        return <ErrorSegment message="Default Fallthrough" />;
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Sextant</title>
      </Helmet>
      <StripDiv className="header-strip">
        <Button basic content="Back" icon="arrow left" onClick={() => history.goBack()} />
        <Wrapper>
          <StyledLogo src={LogoGreen} />
          <StyledHeader as="h1">Sextant</StyledHeader>
        </Wrapper>
      </StripDiv>
      <Grid container>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Header as="h2">
              {model.name}
              {model.description ? <Header.Subheader>{model.description} </Header.Subheader> : null}
            </Header>
          </Grid.Column>
          <Grid.Column floated="right" style={{ paddingLeft: 100 }}>
            <Form loading={state.loadingStage ? true : false}>
              <Form.Group>
                <SemanticDatepicker
                  clearable={false}
                  label="Start Date"
                  value={state.startDate}
                  filterDate={(date) => date < new Date()}
                  name="startDate"
                  onChange={handleChange}
                />
                <SemanticDatepicker
                  clearable={false}
                  label="End Date"
                  value={state.endDate}
                  disabled={state.startDate ? false : true}
                  filterDate={(date) => date > state.startDate && date < new Date()}
                  name="endDate"
                  onChange={handleChange}
                />
                <Form.Button
                  onClick={handleCompareSubmit}
                  style={{ position: 'absolute', bottom: 0 }}
                  disabled={state.startDate && state.endDate ? false : true}
                  primary
                  icon={state.modelResult ? 'refresh' : 'paper plane'}
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        {state.modelResult ? (
          <>
            <ModelStats csv={state.paths.csv} />
            <Grid.Row>
              <Grid.Column>
                <Menu secondary>
                  <Menu.Item header>Model Stage</Menu.Item>
                  <Menu.Item
                    active={state.activeTab === 'compare'}
                    onClick={() => setState({ ...state, activeTab: 'compare' })}
                  >
                    Compare
                  </Menu.Item>
                  <Menu.Item
                    active={state.activeTab === 'refine'}
                    onClick={() => setState({ ...state, activeTab: 'refine' })}
                  >
                    Refine
                  </Menu.Item>
                  <Menu.Item
                    active={state.activeTab === 'simulate'}
                    onClick={() => setState({ ...state, activeTab: 'simulate' })}
                  >
                    Simulate
                  </Menu.Item>
                </Menu>
              </Grid.Column>
            </Grid.Row>
          </>
        ) : state.loadingStage ? null : (
          <Message warning>Please choose a date range, and run the model to start!</Message>
        )}
        {renderContent()}
      </Grid>
    </>
  );
};
