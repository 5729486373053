import { useQuery, gql, useMutation } from '@apollo/client';
import Axios from 'axios';
import ace from 'brace';
import { deepParseJson } from 'deep-parse-json';
import { JsonEditor as Editor } from 'jsoneditor-react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input, Button, Confirm, Icon, Accordion, Select, Dropdown } from 'semantic-ui-react';
import 'jsoneditor-react/es/editor.min.css';

import 'brace/mode/json';
import 'brace/theme/solarized_dark';

import { ParamsType, SonarClient } from '../../../types';

import { DELETE_ESCALATION, UPDATE_ESCALATION } from '../gql/mutations.gql';
import { GET_CLIENT_BY_UUID, GET_ESCALATIONS } from '../gql/queries.gql';
//import { isCompositeType } from 'graphql';

//Types
type Accordian = {
  activeIndex: number;
};

type Escalation = {
  config: string;
  escalation_id: string;
  ga_ads_cid: number;
  ga_campaigns: any[];
  loading: boolean;
  name: string;
  type: string;
};

type GoogleAdCampaignOptions = {
  options: any[];
};

type sonar_ga_campaigns_insert_input = {
  escalation_id: string;
  id: number;
  name: string;
  resource_name: string;
};

const escalationTypes = [
  { key: 'notification', text: 'Failure Notification', value: 'NOTIFICATION' },
  { key: 'stop_start_google_ads', text: 'Pause/Start Google Ads', value: 'START_STOP_GOOGLE_ADS' },
];

let clientCampaignOptions = [];

type Props = {
  escalation_id: string;
  index: number;
};

interface confirmDialog {
  open: boolean;
  result: string;
}

const AssertionForm = ({ escalation_id, index }: Props) => {
  const { client_id } = useParams<ParamsType>();
  const [accordianState, setAccordianState] = useState<Accordian>({
    activeIndex: -1,
  });

  const [confirmState, setConfirmState] = useState<confirmDialog>({
    open: false,
    result: '',
  });

  const [sonarClientState, setSonarClientState] = useState<SonarClient>();

  //Setup Test State
  const [escalationState, setEscalationState] = useState<Escalation>();

  //Setup Google Ad Campaign State
  const [googleAdCampaignOptionsState, setGoogleAdCampaignOptionsState] = useState<GoogleAdCampaignOptions>({
    options: [],
  });

  //Get Test Apollo Query
  const {
    loading: queryLoading,
    error: queryError,
    data,
  } = useQuery(GET_ESCALATIONS, {
    variables: { escalation_id },
  });

  const {
    loading: clientLoading,
    error: clientError,
    data: clientData,
  } = useQuery(GET_CLIENT_BY_UUID, {
    variables: { client_id },
    fetchPolicy: 'no-cache',
  });

  //Update Step
  const [updateEscalation, { loading: mutationLoading, error: mutationError }] = useMutation(UPDATE_ESCALATION);

  //Delete Step
  const [deleteEscalation, { loading: mutationDeleteLoading, error: mutationDeleteError }] = useMutation(
    DELETE_ESCALATION,
    {
      onCompleted: (data) => {
        if (data.delete_sonar_escalation.affected_rows) {
          //Brute force for now :P
          window.location.reload();
        }
      },
    },
  );

  if (queryLoading) {
    return <p>Loading...</p>;
  }
  if (queryError) {
    return <p>Query Error :(</p>;
  }

  if (clientLoading) {
    return <p>Loading...</p>;
  }
  if (clientError) {
    return <p>Client Error :(</p>;
  }

  const handleAccordianClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = accordianState;
    const newIndex = activeIndex === index ? -1 : index;

    setAccordianState({ activeIndex: newIndex });
  };

  const handleEscalationChange = (e, optionValue) => {
    const value = optionValue;
    //const { activeIndex } = accordianState;
    //const newIndex = activeIndex === index ? -1 : index;
    //TODO: Update for both Test and Prod Manager accounts when app approved.
    // adwords_client_id is expected to be a string from the api.
    if (value == 'START_STOP_GOOGLE_ADS' && googleAdCampaignOptionsState.options.length == 0) {
      setEscalationState({ ...escalationState, loading: true });
      getGoogleAdsCampaigns(value);
    } else {
      setEscalationState({ ...escalationState, type: value, ga_campaigns: [], loading: false });
    }
  };

  const handleGACampaignsChange = (e, optionValue) => {
    const campArray = [];
    for (const opv of optionValue) {
      campArray.push(opv);
    }
    //const { activeIndex } = accordianState;
    //const newIndex = activeIndex === index ? -1 : index;
    //TODO: Update for both Test and Prod Manager accounts when app approved.
    // adwords_client_id is expected to be a string from the api.
    setEscalationState({ ...escalationState, ga_campaigns: campArray });
    //console.log(escalationState.ga_campaigns);
  };

  const getGoogleAdsCampaigns = async (esc_type) => {
    const res = await Axios.post('https://tvcku4idta.execute-api.us-west-2.amazonaws.com/v0/gads-campaigns', {
      headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' },
      body: { adwords_client_id: sonarClientState.google_ads_client_id.toString() },
    });
    if (res && res.status === 200) {
      const campaignData = JSON.parse(res.data);

      clientCampaignOptions = campaignData.map((campData) => ({
        key: campData.campaign.resource_name,
        text: campData.campaign.name,
        value: campData.campaign.id,
      }));

      const google_ads_options: GoogleAdCampaignOptions = {
        options: clientCampaignOptions,
      };

      setGoogleAdCampaignOptionsState(google_ads_options);

      setEscalationState({
        ...escalationState,
        type: esc_type,
        ga_ads_cid: parseInt(sonarClientState.google_ads_client_id),
        loading: false,
      });

      // if(campaign.){

      //   setGoogleAdCampaignState()
      // }
      // setState({
      //   ...state,
      //   loadingStage: { percent: 100, message: 'Crunching Numbers' },
      // });
      // await new Promise((resolve) => setTimeout(resolve, 1000));
      // setState({
      //   ...state,
      //   graphStates: res.data.states.split('|'),
      //   loadingStage: null,
      //   modelResult: JSON.parse(res.data.body),
      //   paths: mcf,
      //   transitionMatrix: res.data.transition,
      // });
    }
  };

  const handleDelete = () => {
    deleteEscalation({
      variables: { escalation_id: escalationState?.escalation_id },
    });
  };

  const handleCancel = (e, value) => {
    setConfirmState({ open: false, result: value });
  };

  const { activeIndex } = accordianState;

  // eslint-disable-next-line array-callback-return
  data.sonar_escalation.map((escalation: Escalation) => {
    if (!escalationState) {
      const optIds = escalation.ga_campaigns.map((incoming_camp) => {
        return incoming_camp.id.toString();
      });

      setEscalationState({
        config: escalation.config,
        escalation_id: escalation.escalation_id,
        ga_ads_cid: escalation.ga_ads_cid,
        ga_campaigns: optIds,
        loading: false,
        name: escalation.name,
        type: escalation.type,
      });
    }
  });

  if (clientData.client_client_by_pk) {
    const sonarClient: SonarClient = clientData.client_client_by_pk;
    if (!sonarClientState) {
      setSonarClientState(sonarClient);
    }
  }

  if (!escalationState) {
    return <p>Loading...</p>;
  } else {
    if (
      escalationState.type == 'START_STOP_GOOGLE_ADS' &&
      googleAdCampaignOptionsState.options.length == 0 &&
      escalationState.loading == false
    ) {
      console.log('Getting Campaings');
      setEscalationState({ ...escalationState, loading: true });
      getGoogleAdsCampaigns(escalationState.type);
    }
  }

  return (
    <div>
      <Accordion.Title active={activeIndex === index} index={index} onClick={handleAccordianClick}>
        <Icon name="dropdown" />
        {escalationState.name}
      </Accordion.Title>
      <Accordion.Content active={activeIndex === index}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            const updated = new Date();
            const campaignsToSave = [];
            for (const campID of escalationState.ga_campaigns) {
              const matchingOption = clientCampaignOptions.find((item) => item.value == campID);
              if (matchingOption) {
                const campToPause: sonar_ga_campaigns_insert_input = {
                  escalation_id: escalationState.escalation_id,
                  id: campID,
                  name: matchingOption.text,
                  resource_name: matchingOption.key,
                };
                campaignsToSave.push(campToPause);
              }
            }
            updateEscalation({
              variables: {
                config: escalationState.config,
                escalation_id: escalationState.escalation_id,
                ga_ads_cid: escalationState.ga_ads_cid,
                ga_campaigns: campaignsToSave,
                loading: escalationState.loading,
                name: escalationState.name,
                type: escalationState.type,
                updated,
              },
            });
          }}
        >
          <Form.Group widths="equal">
            <Form.Field
              id="form-input-control-test-escalation-name"
              control={Input}
              value={escalationState.name}
              label="Escalation Name"
              placeholder="Escalation name"
              onChange={(e, { value }) => setEscalationState({ ...escalationState, name: value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              id="form-input-control-test-escalation-type"
              control={Select}
              label="Escalation Type"
              value={escalationState.type}
              loading={escalationState.loading}
              options={escalationTypes}
              onChange={(e, { value }) => handleEscalationChange(e, value)}
            />
          </Form.Group>
          {escalationState.type === 'START_STOP_GOOGLE_ADS' && googleAdCampaignOptionsState.options.length > 0 ? (
            <Form.Group>
              <Form.Field
                id="form-input-control-test-escalation-google-ads-campaigns"
                control={Dropdown}
                label="Select Google Ads Campaign(s) to Pause"
                fluid
                multiple
                search
                selection
                value={escalationState.ga_campaigns}
                loading={clientLoading}
                options={googleAdCampaignOptionsState.options}
                onChange={(e, { value }) => handleGACampaignsChange(e, value)}
              />
            </Form.Group>
          ) : (
            ''
          )}
          <Editor
            id="form-textarea-control-test-escalation-config"
            value={deepParseJson(escalationState.config)}
            mode="code"
            ace={ace}
            theme="ace/theme/solarized_dark"
            onChange={(json) => {
              if (json) {
                setEscalationState({
                  config: JSON.stringify(json),
                  escalation_id: escalationState.escalation_id,
                  ga_ads_cid: escalationState.ga_ads_cid,
                  ga_campaigns: escalationState.ga_campaigns,
                  loading: escalationState.loading,
                  name: escalationState.name,
                  type: escalationState.type,
                });
              }
            }}
          />
          <p></p>
          <Button primary type="submit">
            Save Escalation
          </Button>
          <Button
            color="red"
            onClick={() => {
              setConfirmState({ ...confirmState, open: true });
            }}
          >
            Delete Escalation
          </Button>
          <Confirm
            open={confirmState.open}
            content="Permanently delete this Escalation?"
            onCancel={handleCancel}
            onConfirm={handleDelete}
            negative
          />
          {mutationLoading && <p>Loading...</p>}
          {mutationError && <p>Error :( Please try again</p>}
          {mutationDeleteLoading && <p>Loading...</p>}
          {mutationDeleteError && <p>Error :( Please try again</p>}
        </Form>
      </Accordion.Content>
    </div>
  );
};

export default AssertionForm;
