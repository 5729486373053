import { useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { Breadcrumb, Container, Header } from 'semantic-ui-react';
import DashboardIcon from '../../assets/images/compass/dashboard_icon.svg';
import { ErrorSegment, LoadingSegment } from '../../components';
import { GET_METABASE_DASHBOARDS_PK } from '../../graphql/queries';
import { ParamsType } from '../../types';

export const Folders = () => {
  const history = useHistory();
  const { folder_id } = useParams<ParamsType>();
  const { loading, error, data } = useQuery(GET_METABASE_DASHBOARDS_PK, {
    variables: {
      folder_id,
    },
  });

  if (loading) {
    return <LoadingSegment />;
  } else if (error) {
    return <ErrorSegment message={JSON.stringify(error)} />;
  }
  if (data && data.compass_client_folder_by_pk) {
    return (
      <Container style={{ padding: '25px 0 0 0' }}>
        <Breadcrumb>
          <Breadcrumb.Section link onClick={() => history.goBack()}>
            Compass
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section>{data.compass_client_folder_by_pk.name}</Breadcrumb.Section>
        </Breadcrumb>
        <Header as="h3">Dashboards</Header>

        <div className="card-container">
          {data.compass_client_folder_by_pk.metabase_dashboards.map((item) => (
            <div
              className="nav-card"
              key={item.dashboard_id}
              onClick={() => history.push(`/compass/dashboard/${item.dashboard_id}`)}
            >
              <img alt="dashboard-icon" src={DashboardIcon} />
              <div className="card-name">{item.name}</div>
            </div>
          ))}
        </div>
      </Container>
    );
  }
  return (
    <div>
      <h1>This is the Dashboard View (missing vars for metabase demo)</h1>
    </div>
  );
};
