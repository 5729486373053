import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth } from 'aws-amplify';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import Login from './pages/Login/Login';
import Portal from './router/Portal';
import PrivateRoute from './router/PrivateRoute';
import store from './store';
import { getUserAuth } from './utils/amplify';

export const history = createBrowserHistory();

/** Configure amplify */
Auth.configure({
  region: process.env.REACT_APP_AUTH_REGION || 'us-west-2',
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
});

const asyncAuthLink = setContext(async () => {
  // this is an async call, it will be done before each request
  const user = await getUserAuth(false);
  return {
    headers: {
      authorization: user !== undefined && user !== null ? `Bearer ${user.signInUserSession.idToken.jwtToken}` : '',
    },
  };
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPH_URL,
});

const client = new ApolloClient({
  link: asyncAuthLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          client_client_by_pk: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            merge(existing = [], incoming: any) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
    },
  }),
});

const App = () => {
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <Router history={history}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute path="*" component={Portal} />
          </Switch>
        </Router>
      </ApolloProvider>
    </Provider>
  );
};

export default App;
