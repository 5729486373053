import { useHistory } from 'react-router-dom';
import { Card } from 'semantic-ui-react';

export const SextantCard = ({ model }) => {
  const history = useHistory();
  return (
    <Card>
      <Card.Content>
        <Card.Header>{model.name}</Card.Header>
        {model.description ? <Card.Description>{model.description}</Card.Description> : null}
      </Card.Content>
      <Card.Content extra onClick={() => history.push(`/sextant/${model.client_model_id}`)}>
        Open
      </Card.Content>
    </Card>
  );
};
