import { useQuery, useMutation, gql } from '@apollo/client';

import { Dropdown } from 'semantic-ui-react';

const GET_VALID_CLIENTS = gql`
  query GetValidClients {
    client_client(order_by: { name: asc }) {
      name
      client_id
    }
  }
`;

const GET_ACTIVE_CLIENT = gql`
  query GetActiveClient {
    client_user {
      active_client_id
    }
  }
`;

const SET_ACTIVE_CLIENT = gql`
  mutation MyMutation($active_client_id: uuid) {
    update_client_user(where: {}, _set: { active_client_id: $active_client_id }) {
      affected_rows
    }
  }
`;

export const ClientPicker = () => {
  const { loading, error, data: clients } = useQuery(GET_VALID_CLIENTS);
  const { data: current } = useQuery(GET_ACTIVE_CLIENT, {
    fetchPolicy: 'no-cache',
  });
  const [setActiveClient, { loading: mutationLoading, error: mutationError }] = useMutation(SET_ACTIVE_CLIENT, {
    onCompleted: () => window.location.reload(),
  });

  if (error || mutationError) {
    return <p>Error :(</p>;
  }

  if (clients && clients.client_client && clients.client_client.length === 1) {
    return <>{clients.client_client[0].name}</>;
  }

  return (
    <Dropdown
      style={{ minWidth: '300px' }}
      onChange={(event, data) => {
        setActiveClient({ variables: { active_client_id: data.value } });
      }}
      upward={false}
      value={current ? current.client_user[0].active_client_id : null}
      fluid
      scrolling
      loading={mutationLoading || loading}
      options={
        clients
          ? clients.client_client.map((item) => ({
              key: item.client_id,
              text: item.name,
              value: item.client_id,
            }))
          : null
      }
    />
  );
};
