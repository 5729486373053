import { useQuery, useMutation } from '@apollo/client';
import { Form, Cascader, Button, Modal } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import {
  ADD_GOOGLE_ANALYTICS_ACCOUNT,
  ADD_GOOGLE_ANALYTICS_PROPERTY,
  ADD_GOOGLE_ANALYTICS_VIEW,
} from '../../graphql/mutations';
import { GET_GOOGLE_ANALYTICS } from '../../graphql/queries';
import { GoogleAnalyticsProps } from '../../types';
import { filter } from '../../utils/string';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function getAccessToken() {
  const params = new URLSearchParams();
  params.append('client_id', process.env.REACT_APP_GOOGLE_ANALYTICS_OAUTH_CREDENTIALS_CLIENT_ID);
  params.append('client_secret', process.env.REACT_APP_GOOGLE_ANALYTICS_OAUTH_CREDENTIALS_CLIENT_SECRET);
  params.append('refresh_token', process.env.REACT_APP_GOOGLE_ANALYTICS_OAUTH_CREDENTIALS_REFRESH_TOKEN);
  params.append('grant_type', 'refresh_token');
  try {
    const resp = await axios.post('https://oauth2.googleapis.com/token', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return resp.data.access_token;
  } catch (e) {
    return e;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function getAnalyticsAccounts() {
  const access_token = await getAccessToken();
  const url = 'https://www.googleapis.com/analytics/v3/management/accountSummaries';
  const { data } = await axios.get(url, {
    headers: {
      Authorization: 'Bearer ' + access_token,
    },
  });
  return data.items;
}

const GoogleAnalytics = ({ client_id }: GoogleAnalyticsProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [state, setState] = useState<any>({
    show: false,
    client_id,
  });
  const { loading, error, data, refetch } = useQuery(GET_GOOGLE_ANALYTICS, {
    variables: { client_id: { _eq: parseInt(client_id) } },
  });
  const [addAccount] = useMutation(ADD_GOOGLE_ANALYTICS_ACCOUNT);
  const [addProperty] = useMutation(ADD_GOOGLE_ANALYTICS_PROPERTY);
  const [addView] = useMutation(ADD_GOOGLE_ANALYTICS_VIEW);

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showModal = async () => {
    const accounts = await getAnalyticsAccounts();
    setState({
      ...state,
      accounts,
      accountOptions: accounts.map((account) => ({
        value: account.id,
        label: `${account.name} - ${account.id}`,
      })),
    });
    setVisible(true);
  };

  const handleOk = async () => {
    const acc = state.accounts.filter((account) => account.id === state.selectedAccount)[0];
    setConfirmLoading(true);
    await addAccount({
      variables: {
        starred: acc.starred ? true : false,
        name: acc.name,
        ga_account_id: acc.id,
        client_id: state.client_id,
      },
    });
    for (const property of acc.webProperties) {
      await addProperty({
        variables: {
          client_id: state.client_id,
          ga_account_id: acc.id,
          ga_property_id: property.id,
          name: property.name,
          starred: property.starred ? true : false,
        },
      });
      for (const view of property.profiles) {
        await addView({
          variables: {
            client_id: state.client_id,
            ga_account_id: acc.id,
            ga_property_id: property.id,
            ga_view_id: view.id,
            name: view.name,
            starred: view.starred ? true : false,
          },
        });
      }
    }
    await refetch();
    setVisible(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onChange(value, selectedOptions) {
    // console.log( [ value, selectedOptions ] );
    setState({ ...state, selectedAccount: value[0] });
  }

  if (loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>{JSON.stringify(error)}</p>;
  }

  return (
    <>
      <div style={{ textAlign: 'center', width: '100%', marginTop: '25px' }}>
        <h2>Google Analytics</h2>
        <Button type="primary" onClick={showModal} style={{ marginBottom: '25px' }}>
          Add Account
        </Button>
        <Modal
          title="Create New Client"
          visible={visible}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
        >
          <Form>
            <Form.Item required label="Analytics Account" name="name">
              <Cascader
                disabled={!state.accountOptions}
                options={state.accountOptions}
                onChange={onChange}
                placeholder="Please select"
                showSearch={{ filter }}
              />
            </Form.Item>
          </Form>
        </Modal>

        {data && data.google_analytics_account.length > 0 ? (
          <>
            <h4>Accounts</h4>
            <ul>
              {data.google_analytics_account.map((acc, index) => (
                <li key={index}>{`${acc.name} - ${acc.ga_account_id}`}</li>
              ))}
            </ul>
          </>
        ) : null}
      </div>
    </>
  );
};

export default GoogleAnalytics;
