import { useQuery } from '@apollo/client';
import { GET_CURRENT_CLIENT } from '../../graphql/queries';

import GoogleAnalytics from './GoogleAnalytics';

export const ClientManager = () => {
  const { loading, error, data } = useQuery(GET_CURRENT_CLIENT);

  if (loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error :(</p>;
  }

  return (
    <>
      <div style={{ textAlign: 'center', width: '100%', marginTop: '25px' }}>
        {data && data.client_user ? (
          <>
            <h1>Data Management</h1>
            <GoogleAnalytics client_id={data.client_user[0].active_client_id} />
            <h3>Google Search Console</h3>
            <h3>Google Ads</h3>
            <h3>Bing Ads</h3>
            <h3>Facebook Ads</h3>
            <h3>Taboola</h3>
          </>
        ) : null}
      </div>
    </>
  );
};
