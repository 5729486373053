import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Grid, Card } from 'semantic-ui-react';
import { VictoryPie, VictoryLegend, VictoryLabel, VictoryTooltip } from 'victory';
import { GET_SERVICE_LOGS } from '../../../graphql/queries';
import { ClientId, LogType, ServicesLog, TestType } from '../../../types';
import '../css/sonar.css';

import { GET_ACTIVE_TESTS } from '../gql/queries.gql';

const currentDate: Date = new Date();
currentDate.setDate(currentDate.getDate() - 180);
const backDate: Date = new Date(currentDate);
const time_resolved: string = backDate.toISOString();

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styleData: Record<any, any>;
};

const PieCharts = ({ styleData }: Props) => {
  const { client_id } = useParams<ClientId>();

  const { loading: servicesLoading, data: servicesData } = useQuery<ServicesLog>(GET_SERVICE_LOGS, {
    variables: { client_id, time_resolved },
  });

  const { loading, data: active } = useQuery(GET_ACTIVE_TESTS, {
    variables: { client_id },
  });

  // //Use Apollo's loading feature to prevent errors
  if (loading || servicesLoading) {
    return null;
  }

  const getTestTypeAggregates = (tests) => {
    const testTypeData: TestType = {
      ANALYTICS: { count: 0, issues: 0 },
      CAMPAIGN_MGMT: { count: 0, issues: 0 },
      CONVERSION_FLOW: { count: 0, issues: 0 },
      null: { count: 0, issues: 0 },
      TOTAL: { count: 0, issues: 0 },
      USER_FLOW: { count: 0, issues: 0 },
    };
    for (const test of tests) {
      const test_type = test.test.test_type;

      testTypeData[test_type].count++;
      testTypeData.TOTAL.count++;
      if (test.test.test_status === 'FAILING' || test.test.resolved_state === false) {
        testTypeData[test_type].issues++;
        testTypeData.TOTAL.issues++;
      }
    }
    return testTypeData;
  };

  const getTestLogAggregates = (logs) => {
    const testTypeData: LogType = {
      ANALYTICS: 0,
      CAMPAIGN_MGMT: 0,
      CONVERSION_FLOW: 0,
      null: 0,
      TOTAL: 0,
      USER_FLOW: 0,
    };
    if (logs) {
      for (const log of logs) {
        const test_type = log.test.test_type;
        if (log.resolved_status === 'FIXED') {
          testTypeData[test_type]++;
          testTypeData.TOTAL++;
        }
      }
    }
    return testTypeData;
  };

  const aggregates = getTestTypeAggregates(active.client_client_by_pk.client_client_test);
  const logAggregates = getTestLogAggregates(servicesData.services_log);

  const getChartData = (aggregates, styleData, type) => {
    const data = [];

    if (!aggregates || !styleData) {
      return data;
    }
    if (type === 'active') {
      for (const key in aggregates) {
        if (aggregates[key]?.count && aggregates[key].count > 0 && styleData[key]) {
          data.push({
            x: styleData[key].name,
            y: aggregates[key]?.count || 0,

            label: aggregates[key].count + ' ' + styleData[key].name,
          });
        }
      }
    }
    if (type === 'issues') {
      for (const key in aggregates) {
        if (aggregates[key]?.issues && aggregates[key].issues > 0 && styleData[key]) {
          data.push({
            x: styleData[key].name,
            y: aggregates[key]?.issues || 0,

            label: aggregates[key].issues + ' ' + styleData[key].name,
          });
        }
      }
    }
    if (type === 'resolved') {
      for (const key in aggregates) {
        if (aggregates[key] && aggregates[key] > 0 && styleData[key]) {
          data.push({
            x: styleData[key].name,
            y: aggregates[key],
            label: aggregates[key] + ' ' + styleData[key].name,
          });
        }
      }
    }
    return data;
  };

  const getTheAngle = (aggregates, type) => {
    let checkAngle = 0;
    if (type === 'active') {
      checkAngle =
        (aggregates.ANALYTICS.count ? 1 : 0) +
        (aggregates.USER_FLOW.count ? 1 : 0) +
        (aggregates.null.count ? 1 : 0) +
        (aggregates.CONVERSION_FLOW.count ? 1 : 0);
    }
    if (type === 'issues') {
      checkAngle =
        (aggregates.ANALYTICS?.issues ? 1 : 0) +
        (aggregates.USER_FLOW?.issues ? 1 : 0) +
        (aggregates.null?.issues ? 1 : 0) +
        (aggregates.CONVERSION_FLOW?.issues ? 1 : 0);
    }
    if (type === 'resolved') {
      checkAngle =
        (aggregates.ANALYTICS ? 1 : 0) +
        (aggregates.USER_FLOW ? 1 : 0) +
        (aggregates.CAMPAIGN_MGMT ? 1 : 0) +
        (aggregates.null ? 1 : 0) +
        (aggregates.CONVERSION_FLOW ? 1 : 0);
    }

    return checkAngle <= 1 ? 0 : checkAngle;
  };

  const getLegendData = (aggregates, styleData) => {
    const data = [];
    if (aggregates && styleData) {
      for (const key in aggregates) {
        if (styleData[key]) {
          data.push({
            name: styleData[key].name,

            symbol: { fill: styleData[key].color, type: 'square' },
          });
        }
      }
    }
    return data;
  };

  return (
    <div>
      <Grid container style={{ paddingTop: '2rem' }}>
        <Card fluid>
          <Grid container columns={3}>
            <Grid.Row>
              <Grid.Column>
                <svg viewBox="0 0 400 400">
                  <VictoryPie
                    labelComponent={
                      <VictoryTooltip
                        x={200}
                        y={150}
                        orientation="top"
                        renderInPortal={false}
                        pointerLength={0}
                        flyoutWidth={100}
                        flyoutHeight={20}
                        flyoutStyle={{
                          fill: 'transparent',
                          stroke: 'transparent',
                        }}
                        style={{
                          fontFamily: "'Montserrat','Helvetica Neue',Arial,Helvetica,sans-serif",
                        }}
                      />
                    }
                    standalone={false}
                    animate={{ duration: 1000 }}
                    width={400}
                    height={400}
                    padAngle={getTheAngle(aggregates, 'active')}
                    data={getChartData(aggregates, styleData, 'active')}
                    innerRadius={115}
                    labels={() => null}
                    style={{
                      data: {
                        fill: ({ datum }) => {
                          const keys = Object.keys(styleData);
                          let color = '#939498';
                          for (const key of keys) {
                            if (datum.x === styleData[key].name) {
                              color = styleData[key].color;
                            }
                          }
                          return color;
                        },
                      },
                    }}
                  />
                  <VictoryLabel
                    textAnchor="middle"
                    style={{
                      fontSize: 20,
                      textTransform: 'uppercase',
                      fontWeight: 300,
                      fontFamily: '"Montserrat", "Arial", "Helvetica", sans-serif',
                    }}
                    x={200}
                    y={200}
                    text={aggregates.TOTAL?.count > 0 ? aggregates.TOTAL.count + ' Active Tests' : 'No Active Tests'}
                  />
                </svg>
              </Grid.Column>
              <Grid.Column>
                <svg viewBox="0 0 400 400">
                  <VictoryPie
                    labelComponent={
                      <VictoryTooltip
                        x={200}
                        y={150}
                        orientation="top"
                        pointerLength={0}
                        flyoutWidth={100}
                        flyoutHeight={20}
                        renderInPortal={false}
                        flyoutStyle={{
                          fill: 'transparent',
                          stroke: 'transparent',
                        }}
                        style={{
                          fontFamily: "'Montserrat','Helvetica Neue',Arial,Helvetica,sans-serif",
                        }}
                      />
                    }
                    standalone={false}
                    animate={{ duration: 1000 }}
                    width={400}
                    height={400}
                    padAngle={getTheAngle(aggregates, 'issues')}
                    data={getChartData(aggregates, styleData, 'issues')}
                    innerRadius={115}
                    labels={() => null}
                    style={{
                      data: {
                        fill: ({ datum }) => {
                          const keys = Object.keys(styleData);
                          let color = '#939498';
                          for (const key of keys) {
                            if (datum.x === styleData[key].name) {
                              color = styleData[key].color;
                            }
                          }
                          return color;
                        },
                      },
                    }}
                  />
                  <VictoryLabel
                    textAnchor="middle"
                    style={{
                      fontSize: 20,
                      textTransform: 'uppercase',
                      fontWeight: 300,
                      fontFamily: '"Montserrat", "Arial", "Helvetica", sans-serif',
                    }}
                    x={200}
                    y={200}
                    text={aggregates.TOTAL?.issues > 0 ? aggregates.TOTAL.issues + ' Open Issues' : 'No Open Issues'}
                  />
                </svg>
              </Grid.Column>
              <Grid.Column>
                <svg viewBox="0 0 400 400">
                  <VictoryPie
                    labelComponent={
                      <VictoryTooltip
                        x={200}
                        y={150}
                        orientation="top"
                        pointerLength={0}
                        flyoutWidth={100}
                        flyoutHeight={20}
                        renderInPortal={false}
                        flyoutStyle={{
                          fill: 'transparent',
                          stroke: 'transparent',
                        }}
                        style={{
                          fontFamily: "'Montserrat','Helvetica Neue',Arial,Helvetica,sans-serif",
                        }}
                      />
                    }
                    standalone={false}
                    animate={{ duration: 1000 }}
                    width={400}
                    height={400}
                    padAngle={getTheAngle(logAggregates, 'resolved')}
                    data={getChartData(logAggregates, styleData, 'resolved')}
                    innerRadius={115}
                    labels={() => null}
                    style={{
                      data: {
                        fill: ({ datum }) => {
                          const keys = Object.keys(styleData);
                          let color = '#939498';
                          for (const key of keys) {
                            if (datum.x === styleData[key].name) {
                              color = styleData[key].color;
                            }
                          }
                          return color;
                        },
                      },
                    }}
                  />
                  <VictoryLabel
                    textAnchor="middle"
                    style={{
                      fontSize: 20,
                      textTransform: 'uppercase',
                      fontWeight: 300,
                      fontFamily: '"Montserrat", "Arial", "Helvetica", sans-serif',
                    }}
                    x={200}
                    y={200}
                    text={logAggregates.TOTAL + ' Resolved'}
                  />
                </svg>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column
                width={16}
                style={{
                  textAlign: 'center',
                  paddingBottom: '3rem',
                  marginTop: '-1rem',
                }}
              >
                <VictoryLegend
                  x={40}
                  orientation="horizontal"
                  height={20}
                  gutter={20}
                  style={{
                    labels: {
                      fontSize: 7,
                      fontWeight: 300,
                      fill: '#6E7277',
                      fontFamily: "'Lato', 'Arial', 'Helvetica', sans-serif",
                    },
                  }}
                  data={getLegendData(aggregates, styleData)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card>
      </Grid>
    </div>
  );
};

export default PieCharts;
