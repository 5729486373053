import { useHistory } from 'react-router-dom';
import { Header, Segment, Button, Icon, Container } from 'semantic-ui-react';

export const NotFound = () => {
  const history = useHistory();
  return (
    <Container style={{ marginTop: '50px' }}>
      <Segment placeholder>
        <Header icon>
          <Icon name="search" />
          We can&apos;t seem to find that page.
        </Header>
        <Segment.Inline>
          <Button primary onClick={() => history.push('/dashboard')}>
            Go Home
          </Button>
          <Button onClick={() => history.goBack()}>Go Back</Button>
        </Segment.Inline>
      </Segment>
    </Container>
  );
};
