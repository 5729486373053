import { Link } from 'react-router-dom';
import { Grid, Card, Image } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledDescription = styled(Card.Description)`
  margin-bottom: 25px;
`;

const StyledMeta = styled(Card.Meta)`
  margin-bottom: 25px;
  text-align: center;
`;

const StyledContent = styled(Card.Content)`
  opacity: 0.8;
`;

export const ActionCard = ({ icon, title, subTitle, link, buttonColor }) => {
  return (
    <Grid.Column width={5}>
      <Card fluid>
        <Card.Content>
          <StyledDescription>
            <Image src={icon} width="75%"></Image>
          </StyledDescription>
          <StyledMeta>{subTitle}</StyledMeta>
        </Card.Content>
        <StyledContent extra style={{ backgroundColor: buttonColor }}>
          <Link to={link}>{title}</Link>
        </StyledContent>
      </Card>
    </Grid.Column>
  );
};
