import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { Card, Image, Button, Grid, Header, Message } from 'semantic-ui-react';
import styled from 'styled-components';
import LogoGreen from '../../assets/images/sextant/logo_dark_green.svg';
import { ErrorSegment, LoadingSegment, SextantCard } from '../../components';
import { GET_CLIENT_MODELS } from '../../graphql/queries';
import ModelBuilder from './ModelBuilder';

const StyledImage = styled(Image)`
  position: absolute !important;
  right: 35px !important;
  bottom: -50px !important;
`;

export const Sextant = () => {
  const { loading, error, data } = useQuery(GET_CLIENT_MODELS);
  const [showBuilder, setShowBuilder] = useState(false);

  const sextantModel = data && data.sextant_client_model;

  if (loading) {
    return <LoadingSegment />;
  } else if (error) {
    return <ErrorSegment message={JSON.stringify(error)} />;
  }

  return (
    <div className="primary-application sextant">
      <div className="header-strip sextant-wrapper">
        <Header as="h1">
          Sextant
          <Header.Subheader>Superior attribution insights for critical marketing decisions. </Header.Subheader>
        </Header>
        <StyledImage src={LogoGreen} />
      </div>
      <Grid container>
        {showBuilder ? (
          <ModelBuilder exit={() => setShowBuilder(false)} />
        ) : (
          <>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Header as="h2">Attribution Models</Header>
              </Grid.Column>
              <Grid.Column floated="right" textAlign="right">
                <Button basic primary content="New Model" icon="plus" onClick={() => setShowBuilder(true)} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Card.Group centered itemsPerRow={3}>
                  {sextantModel && sextantModel.length > 0 ? (
                    sextantModel.map((model) => <SextantCard key={model.client_model_id} model={model} />)
                  ) : (
                    <Message positive content="No saved models found, please create one by clicking 'New Model'!" />
                  )}
                </Card.Group>
              </Grid.Column>
            </Grid.Row>
          </>
        )}
      </Grid>
    </div>
  );
};
