import { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getUserAuth } from '../utils/amplify';

const PrivateRoute = ({
  component: Component,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any & { component: any }): any => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const u = await getUserAuth(false);
    setUser(u);
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (typeof user === 'object') {
          return <Component user={user} {...props} />;
        }
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { referrer: window.location.pathname },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
