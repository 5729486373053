import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Header, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import { Folders } from '../';
import DashboardIcon from '../../assets/images/compass/dashboard_icon.svg';
import FolderIcon from '../../assets/images/compass/folder_icon.svg';
import CompassLogoGreen from '../../assets/images/compass_logo_green.svg';
import { ErrorSegment, LoadingSegment } from '../../components';
import { GET_METABASE_DASHBOARDS } from '../../graphql/queries';
import { ParamsType } from '../../types';

const StyledStripDiv = styled.div`
  align-content: center;
  display: flex;
  flex-direction: column;
  height: 350px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
`;

export const Compass = () => {
  const history = useHistory();
  const { loading, error, data } = useQuery(GET_METABASE_DASHBOARDS);
  const { folder_id } = useParams<ParamsType>();
  const renderOptions = () => {
    if (loading) {
      return <LoadingSegment />;
    } else if (error) {
      return <ErrorSegment message={JSON.stringify(error)} />;
    }
    if (data.compass_client_folder.length === 0) {
      return <ErrorSegment message={'No Dashboards Available'} />;
    }
    if (data && !folder_id) {
      const favorites = [];
      const folders = [];
      for (const folder of data.compass_client_folder) {
        const { folder_id, name } = folder;
        folders.push({ folder_id, name });
        for (const dashboard of folder.metabase_dashboards) {
          if (dashboard.favorite) {
            favorites.push(dashboard);
          }
        }
      }
      return (
        <Container style={{ padding: '25px 0 0 0' }}>
          {favorites && favorites.length > 0 ? (
            <>
              <Header as="h3">Favorites</Header>
              <div className="card-container">
                {favorites.map((item) => (
                  <div
                    className="nav-card"
                    key={item.dashboard_id}
                    onClick={() => history.push(`/compass/dashboard/${item.dashboard_id}`)}
                  >
                    <img alt="dashboard-icon" src={DashboardIcon} />
                    <div className="card-name">{item.name}</div>
                  </div>
                ))}
              </div>
            </>
          ) : null}
          <Header as="h3" style={{ marginTop: '50px' }}>
            Folders
          </Header>
          <div className="card-container">
            {folders.map((item) => (
              <div
                className="nav-card"
                key={item.folder_id}
                onClick={() => history.push(`/compass/folder/${item.folder_id}`)}
              >
                <img alt="folder-icon" src={FolderIcon} />
                <div className="card-name">{item.name}</div>
              </div>
            ))}
          </div>
        </Container>
      );
    } else if (data && folder_id) {
      return <Folders />;
    }
  };
  return (
    <div className="compass">
      <Helmet>
        <title>Compass</title>
      </Helmet>
      <StyledStripDiv className="header-strip">
        <Header as="h1">
          Compass
          <Header.Subheader>
            Achieve clarity and insight for marketing performance reporting, analysis and decision-making.
          </Header.Subheader>
        </Header>
        <Image src={CompassLogoGreen} style={{ position: 'absolute', right: '10px', bottom: '-120px' }} />
      </StyledStripDiv>
      {renderOptions()}
    </div>
  );
};
