import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import CompassLogo from '../assets/images/WHN_Logos_Compass.svg';
import SextantLogo from '../assets/images/WHN_Logos_Sextant.svg';
import SonarLogo from '../assets/images/WHN_Logos_Sonar.svg';
import { NotFound, Sidebar } from '../components';
import {
  Compass,
  Metabase,
  Dashboard,
  Settings,
  Sextant,
  Modeler,
  Sonar,
  ClientLibrary,
  EditClientTest,
  ViewClient,
  ViewTest,
  Support,
  Teams,
} from '../pages';
import { RowWrapper, StyledContainer, StyledColumn } from '../styles/global';

export type MenuItem = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  disabled: boolean;
  icon: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  image?: any;
  key: string;
  primary: boolean;
  title: string;
};

const menuItems: MenuItem[] = [
  {
    component: <Dashboard />,
    disabled: false,
    icon: 'chart line',
    key: 'dashboard',
    primary: false,
    title: 'Dashboard',
  },
  {
    component: <Sextant />,
    disabled: true,
    icon: 'clock outline',
    image: SextantLogo,
    key: 'sextant',
    primary: true,
    title: 'Sextant',
  },
  {
    component: <Compass />,
    disabled: true,
    icon: 'compass',
    image: CompassLogo,
    key: 'compass',
    primary: true,
    title: 'Compass',
  },
  {
    component: <Sonar />,
    disabled: false,
    icon: 'circle outline',
    image: SonarLogo,
    key: 'sonar',
    primary: true,
    title: 'Sonar',
  },
  {
    component: <Teams />,
    disabled: true,
    icon: 'users',
    key: 'teams',
    primary: false,
    title: 'Teams',
  },
  {
    component: <Settings />,
    disabled: false,
    icon: 'settings',
    key: 'settings',
    primary: false,
    title: 'Settings',
  },
  {
    component: <Support />,
    disabled: false,
    icon: 'book',
    key: 'support',
    primary: false,
    title: 'Support',
  },
];

const Portal = () => {
  const { path } = useRouteMatch();
  return (
    <StyledContainer columns="equal" className="cover">
      <RowWrapper>
        <Sidebar menuItems={menuItems} />
        <StyledColumn>
          <Switch>
            {/* <Route exact path="/sextant/:modelId" component={Modeler} /> */}
            <Route exact path="/sonar/client/:client_id/edit_test/:test_id" component={EditClientTest} />
            <Route exact path="/sonar/client/:client_id/view_test/:test_id" component={ViewTest} />
            <Route exact path="/sonar/client/:client_id" component={ViewClient} />
            <Route exact path="/sonar/client/:client_id/library" component={ClientLibrary} />
            {/* <Route exact path="/compass/folder/:folder_id" component={Compass} />
            <Route exact path="/compass/dashboard/:dashboard_id" component={Metabase} /> */}
            <Route exact path="/dashboard">
              <Redirect to="/sonar" />
            </Route>
            <Route exact path="/">
              <Redirect to="/sonar" />
            </Route>
            {menuItems.map((route, index) => (
              <Route key={index} path={path + route.key}>
                {route.component}
              </Route>
            ))}
            {/* No Match route, handles 404s */}
            <Route path="*" component={NotFound} />
          </Switch>
        </StyledColumn>
      </RowWrapper>
    </StyledContainer>
  );
};

export default Portal;
