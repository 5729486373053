import { useHistory, Link } from 'react-router-dom';
import { List, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import SmallLogo from '../assets/images/sonar-white-label-custom-logo.svg';
import { ProfileMenu } from '../components';
import { ColumnWrapper, StyledLogo } from '../styles/global';

const StyledDiv = styled.div`
  position: absolute;
  bottom: 35px;
  left: 25px;
`;

const StyledImage = styled(Image)`
  width: 40px;
  margin: 0 auto;
`;

export const Sidebar = (props) => {
  const history = useHistory();
  return (
    <ColumnWrapper>
      <StyledLogo>
        <Link to="/">
          <StyledImage src={SmallLogo} />
        </Link>
      </StyledLogo>
      <div className="portalNav">
        {/* <List>
          {props.menuItems
            .filter((item) => item.primary)
            .map((view, i) => (
              <List.Item
                key={i}
                className={`navOption ${window.location.pathname.includes(view.key) ? ' active' : ''}`}
                disabled={view.disabled}
                onClick={() => history.push('/' + view.key)}
              >
                <Image src={view.image} />
              </List.Item>
            ))}
        </List> */}
        <StyledDiv>
          <ProfileMenu />
        </StyledDiv>
      </div>
    </ColumnWrapper>
  );
};
