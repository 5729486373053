import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Image, Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import { ClientPicker } from '../components';
import { GET_CURRENT_LOGO } from '../graphql/queries';
import { handleLogout } from '../utils/amplify';

const StyledImage = styled(Image)`
  height: 50px;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
`;

export const ProfileMenu = () => {
  const history = useHistory();
  const { data } = useQuery(GET_CURRENT_LOGO, {
    fetchPolicy: 'cache-and-network',
  });
  const user = data && data.client_user && data.client_user.length === 1 ? data.client_user[0] : null;
  const isActiveClient = user && user.active_client_rel;
  const hasLogoThumb = isActiveClient && isActiveClient.logo_thumb != null;

  return (
    <Dropdown
      icon={null}
      floating
      lazyLoad
      trigger={
        <StyledImage
          src={
            isActiveClient && hasLogoThumb
              ? `data:image/jpeg;base64,${btoa(
                  isActiveClient.logo_thumb
                    .replace('\\x', '')
                    .match(/\w{2}/g)
                    .map((a) => String.fromCharCode(parseInt(a, 16)))
                    .join(''),
                )}`
              : 'https://react.semantic-ui.com/images/wireframe/square-image.png'
          }
          circular
        />
      }
    >
      <Dropdown.Menu>
        <Dropdown.Header content={'Select Client to Test'} style={{ textAlign: 'center' }} />
        <Dropdown.Divider />
        <Dropdown.Header>
          <ClientPicker />
        </Dropdown.Header>
        <Dropdown.Divider />
        <Dropdown.Header content={'Other Settings'} style={{ textAlign: 'center' }} />
        <Dropdown.Divider />
        {user ? (
          <Dropdown.Item
            onClick={() => history.push('/settings/user')}
            content={`Settings for ${user && user.given_name ? user.given_name : ''} ${
              user && user.family_name ? user.family_name : ''
            }`}
          />
        ) : null}
        <Dropdown.Item onClick={() => history.push('/support')} content="Support" />
        <Dropdown.Item onClick={() => handleLogout()} style={{ fontWeight: 'bold' }} content="Logout" />
      </Dropdown.Menu>
    </Dropdown>
  );
};
