import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Card, Button, Image } from 'semantic-ui-react';
import { v4 } from 'uuid';
import { getUserAuth } from '../../../utils/amplify';
import '../css/sonar.css';

type testTemplateType = {
  assertion_templates?: [
    {
      assertion_template_id?: string;
      config?: string;
      name?: string;
      test_template_id?: string;
      type?: string;
      updated?: string;
    },
  ];
  cron_schedule: string;
  description: string;
  enabled: boolean;
  log_bucket?: string;
  log_path?: string;
  name: string;
  network_capture_config: string;
  resolved_state?: boolean;
  start_url?: string;
  step_templates: [
    {
      action_type: string;
      config: string;
      name: string;
      sequence: number;
      step_template_id: string;
      test_template_id: string;
      type: string;
      updated: string;
    },
  ];
  test_status?: string;
  test_template_id: string;
  test_type: string;
  thumbnail_uri: string;
  updated: string;
};

type testStep = {
  action_type: string;
  config: string;
  created: string;
  name: string;
  sequence: number;
  step_id: string;
  type: string;
  updated: string;
};

type testAssertion = {
  assertion_id: string;
  config: string;
  created: string;
  name: string;
  type: string;
  updated: string;
};

//Using a traditional Fetch Pattern to try
const createNewTestFromTemplate = async (user, client_id: string, test_template: testTemplateType, history) => {
  const now = new Date();
  const newTestID = v4();
  const steps = test_template.step_templates.map((stepTemplate) => {
    const newStep: testStep = {
      action_type: stepTemplate.action_type,
      config: stepTemplate.config,
      created: now.toISOString(),
      name: stepTemplate.name,
      sequence: stepTemplate.sequence,
      step_id: v4(),
      type: stepTemplate.type,
      updated: now.toISOString(),
    };
    return newStep;
  });
  const assertions = test_template.assertion_templates.map((assertionTemplate) => {
    const newAssertion: testAssertion = {
      assertion_id: v4(),
      config: assertionTemplate.config,
      created: now.toISOString(),
      name: assertionTemplate.name,
      type: assertionTemplate.type,
      updated: now.toISOString(),
    };
    return newAssertion;
  });

  fetchAddNewTest(user, newTestID, test_template, client_id, steps, assertions)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .then(({ data, errors }) => {
      if (errors) {
        console.error(errors);
      }
      const newTestFormURI = `/sonar/client/${client_id}/edit_test/${newTestID}`;
      history.push(newTestFormURI);
    })
    .catch((error) => {
      console.error(error);
    });
};

const fetchGraphQL = (
  user,
  operationsDoc: string,
  operationName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variables: Record<string, any>,
) => {
  return fetch(process.env.REACT_APP_GRAPH_URL, {
    method: 'POST',
    headers: {
      authorization: user !== undefined && user !== null ? `Bearer ${user.signInUserSession.idToken.jwtToken}` : '',
    },
    body: JSON.stringify({
      query: operationsDoc,
      variables,
      operationName,
    }),
  }).then((result) => result.json());
};

const addTestMutation = `
mutation addNewTest($client_id: uuid!, $test_id: uuid!, $created: timestamp!, $updated: timestamp!, $assertions: [sonar_assertion_insert_input!] = {}, $steps: [sonar_step_insert_input!] = {}, $name: String = "Untitled", $description: String = "", $network_capture_config: jsonb = "", $start_url: String = "", $test_type: String = "", $cron_schedule: String = "") {
  insert_sonar_test(objects: {client_tests: {data: {client_id: $client_id}}, test_id: $test_id, name: $name, enabled: true, created: $created, updated: $updated, assertions: {data: $assertions}, steps: {data: $steps}, description: $description, network_capture_config: $network_capture_config, resolved_state: true, start_url: $start_url, test_type: $test_type, cron_schedule: $cron_schedule}) {
	returning {
	  client_tests {
		client_id
	  }
	  test_id
	  enabled
	}
  }
}
`;

const fetchAddNewTest = (user, test_id, test_template: testTemplateType, client_id, steps, assertions) => {
  const now = new Date();
  return fetchGraphQL(user, addTestMutation, 'addNewTest', {
    assertions,
    client_id,
    created: now.toISOString(),
    cron_schedule: test_template.cron_schedule,
    description: test_template.description,
    name: test_template.name,
    network_capture_config: test_template.network_capture_config,
    start_url: test_template.start_url,
    steps,
    test_id,
    test_type: test_template.test_type,
    updated: now.toISOString(),
  });
};

type Props = {
  client_id: string;
  test_template: testTemplateType;
};

const TestTemplateCards = ({ test_template, client_id }: Props) => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    async function getUser() {
      const u = await getUserAuth(false);
      setUser(u);
    }
    getUser();
  }, []);
  const [testTemplate] = useState<testTemplateType>(test_template);

  const history = useHistory();

  return (
    <Card fluid>
      <Card.Content>
        <Grid container columns={16}>
          <Grid.Column width={2}>
            {testTemplate.thumbnail_uri && <Image src={testTemplate.thumbnail_uri}></Image>}
          </Grid.Column>
          <Grid.Column width={8}>
            <Card.Header>{testTemplate.name}</Card.Header>
            <p className="test-description">{testTemplate.description}</p>
          </Grid.Column>
          <Grid.Column width={6} className="icon-container" style={{ textAlign: 'right', cursor: 'pointer' }}>
            <Button
              color="teal"
              onClick={() => {
                createNewTestFromTemplate(user, client_id, testTemplate, history);
              }}
              className="primary add-test"
            >
              Use this Template
            </Button>
          </Grid.Column>
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default TestTemplateCards;
