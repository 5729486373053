import {
  UserState,
  UserAction,
  SET_THEME,
  SET_CLIENT,
  SET_PROPERTY,
  SET_PROPERTY_TAB,
  SET_PROPERTY_VIEW,
  SET_PROPERTY_VIEW_TAB,
  SET_PROPERTY_VIEW_TAB_ITEM,
  SET_PROPERTY_TAB_ITEM,
  SET_PROPERTY_TAB_SUB_ITEM,
} from '../types';

const initialState: UserState = {
  client: null,
  property: null,
  propertyTab: 'PropSettings',
  propertyTabItem: null,
  propertyTabSubItem: null,
  propertyView: null,
  propertyViewTab: null,
  propertyViewTabItem: null,
  theme: 'light',
  toolView: null,
};

export default (state = initialState, action: UserAction): UserState => {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case SET_CLIENT:
      return {
        ...state,
        client: action.payload,
      };
    case SET_PROPERTY:
      return {
        ...state,
        property: action.payload,
      };
    case SET_PROPERTY_TAB:
      return {
        ...state,
        propertyTab: action.payload,
      };
    case SET_PROPERTY_TAB_ITEM:
      return {
        ...state,
        propertyTabItem: action.payload,
      };
    case SET_PROPERTY_TAB_SUB_ITEM:
      return {
        ...state,
        propertyTabSubItem: action.payload,
      };
    case SET_PROPERTY_VIEW:
      return {
        ...state,
        propertyView: action.payload,
      };
    case SET_PROPERTY_VIEW_TAB:
      return {
        ...state,
        propertyViewTab: action.payload,
      };
    case SET_PROPERTY_VIEW_TAB_ITEM:
      return {
        ...state,
        propertyViewTabItem: action.payload,
      };
    default:
      return state;
  }
};
