import { gql } from '@apollo/client';

export const ADD_NEW_TEST = gql`
  mutation addNewTest($client_id: uuid!, $test_id: uuid!, $created: timestamp!, $updated: timestamp!) {
    insert_sonar_test(
      objects: {
        client_tests: { data: { client_id: $client_id } }
        test_id: $test_id
        name: "Untitled"
        enabled: true
        created: $created
        updated: $updated
      }
    ) {
      returning {
        client_tests {
          client_id
        }
        test_id
        enabled
      }
    }
  }
`;

export const UPDATE_ESCALATION = gql`
  mutation UpdateEscalation(
    $escalation_id: uuid!
    $config: jsonb!
    $name: String!
    $type: String!
    $updated: timestamp!
    $_eq: uuid = ""
    $ga_ads_cid: numeric
    $ga_campaigns: [sonar_ga_campaigns_insert_input!] = {}
  ) {
    update_sonar_escalation_by_pk(
      pk_columns: { escalation_id: $escalation_id }
      _set: { name: $name, type: $type, config: $config, ga_ads_cid: $ga_ads_cid, updated: $updated }
    ) {
      escalation_id
      name
      config
      type
      ga_ads_cid
      updated
    }
    delete_sonar_ga_campaigns(where: { escalation_id: { _eq: $escalation_id } }) {
      affected_rows
    }
    insert_sonar_ga_campaigns(objects: $ga_campaigns) {
      returning {
        id
        name
        resource_name
      }
    }
  }
`;

export const DELETE_ESCALATION = gql`
  mutation DeleteEscalation($escalation_id: uuid = "") {
    delete_sonar_escalation(where: { escalation_id: { _eq: $escalation_id } }) {
      affected_rows
      returning {
        escalation_id
        name
        config
        type
        ga_ads_cid
        updated
      }
    }
  }
`;
