import { clientData } from '../data';
import { ClientState, ClientAction, GET_CLIENT, SET_LOADING, SET_ERROR } from '../types';

const initialState: ClientState = {
  clients: clientData,
  loading: false,
  error: '',
};

export default (state = initialState, action: ClientAction): ClientState => {
  switch (action.type) {
    case GET_CLIENT:
      return {
        clients: action.payload,
        loading: false,
        error: '',
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
