import { Auth } from 'aws-amplify';

export const getUserAuth = async (bypassCache: boolean) => {
  try {
    const user = await Auth.currentAuthenticatedUser({ bypassCache });
    if (user) {
      return user;
    }
  } catch (e) {
    return undefined;
  }
  return undefined;
};

export const handleLogout = async () => {
  try {
    await Auth.signOut({ global: true }).then(() => {
      window.location.reload();
    });
  } catch (err) {
    console.error({ message: err.message });
  }
};
