export const clientData = [
  {
    active: true,
    createdTimestamp: 'string',
    description:
      "Strategy. SEO. Paid Search. Digital Advertising. Analytics. CRO. Engineering. It's time to put value first",
    googleAnalytics: {
      accounts: [
        {
          accountId: '314sdfd',
          properties: [
            {
              currency: 'usd',
              customDimensions: [
                {
                  value: {
                    active: true,
                    dc: true,
                    index: 'sample index',
                    name: 'dem1',
                    scope: 'site',
                  },
                  status: 'ok',
                  views: [
                    {
                      viewId: 'viewId1',
                      status: 'missing',
                    },
                    {
                      viewId: 'viewId2',
                      status: 'ok',
                    },
                  ],
                  notes: 'string',
                },
              ],
              customMetrics: [
                {
                  value: {
                    active: true,
                    index: 1,
                    max_value: '4',
                    min_value: '1',
                    name: 'metric1',
                    scope: 'site',
                    type: 'awesome',
                  },
                  status: 'ok',
                  notes: 'string',
                  views: [
                    {
                      viewId: 'viewId1',
                      status: 'missing',
                    },
                    {
                      viewId: 'viewId2',
                      status: 'ok',
                    },
                  ],
                },
              ],
              eCommerceTracking: {
                value: true,
                status: 'ok',
                notes: 'eCommerceTracking notes wow',
                views: [
                  {
                    viewId: 'viewId1',
                    status: 'missing',
                  },
                  {
                    viewId: 'viewId2',
                    status: 'ok',
                  },
                ],
              },
              enhancedECommerceTracking: {
                value: true,
                status: 'ok',
                notes: 'enhancedECommerceTracking notes wow',
                views: [
                  {
                    viewId: 'viewId1',
                    status: 'missing',
                  },
                  {
                    viewId: 'viewId2',
                    status: 'ok',
                  },
                ],
              },
              filters: [
                {
                  advancedDetails: [
                    {
                      id: 'Advanced Filter 1',
                      notes: 'string',
                      status: 'ok',
                      value: {
                        caseSensitive: true,
                        extract: 'string',
                        field: 'string',
                        fieldIndex: 1,
                        fieldRequired: true,
                        outputConstructor: 'string',
                        outputToField: 'string',
                        outputToFieldIndex: 1,
                        overrideOutputField: true,
                      },
                      views: [
                        {
                          name: 'Super View',
                          status: 'missing',
                        },
                        {
                          name: 'Awesome View',
                          status: 'ok',
                        },
                      ],
                    },
                    {
                      id: 'Advanced Filter 2',
                      notes: 'string',
                      status: 'ok',
                      value: {
                        caseSensitive: true,
                        extract: 'string',
                        field: 'string',
                        fieldIndex: 1,
                        fieldRequired: true,
                        outputConstructor: 'string',
                        outputToField: 'string',
                        outputTofieldIndex: 1,
                        overrideOutputField: true,
                      },
                      views: [
                        {
                          name: 'Super View',
                          status: 'missing',
                        },
                        {
                          name: 'Awesome View',
                          status: 'missing',
                        },
                      ],
                    },
                  ],
                  created: '10-20-1200',
                  excludeDetails: {
                    value: {
                      caseSensitive: true,
                      expressionValue: 'string',
                      field: 'string',
                      fieldIndex: 1,
                      matchType: 'string',
                    },
                    status: 'ok',
                    notes: 'string',
                    views: [
                      {
                        name: 'Super View',
                        status: 'missing',
                      },
                      {
                        name: 'Awesome View',
                        status: 'ok',
                      },
                    ],
                  },
                  id: 'string',
                  includeDetails: {
                    value: {
                      caseSensitive: true,
                      expressionValue: 'string',
                      field: 'div',
                      fieldIndex: 1,
                      matchType: 'string',
                    },
                    status: 'ok',
                    notes: 'string',
                    views: [
                      {
                        name: 'Super View',
                        status: 'missing',
                      },
                      {
                        name: 'Awesome View',
                        status: 'ok',
                      },
                    ],
                  },
                  lowercaseDetails: {
                    value: {
                      field: 'string',
                      fieldIndex: 1,
                    },
                    status: 'ok',
                    notes: 'string',
                    views: [
                      {
                        name: 'Super View',
                        status: 'missing',
                      },
                      {
                        name: 'Awesome View',
                        status: 'ok',
                      },
                    ],
                  },
                  name: 'filter',
                  searchAndReplaceDetails: {
                    value: {
                      caseSensitive: true,
                      field: 'string',
                      fieldIndex: 1,
                      replaceString: 'string',
                      searchString: 'string',
                    },
                    status: 'ok',
                    notes: 'string',
                    views: [
                      {
                        name: 'Super View',
                        status: 'missing',
                      },
                      {
                        name: 'Awesome View',
                        status: 'ok',
                      },
                    ],
                  },
                  type: 'site',
                  updated: '10-21-1200',
                  uppercaseDetails: {
                    value: {
                      field: 'string',
                      fieldIndex: 1,
                    },
                    status: 'ok',
                    notes: 'string',
                    views: [
                      {
                        name: 'Super View',
                        status: 'ok',
                      },
                      {
                        name: 'Awesome View',
                        status: 'ok',
                      },
                    ],
                  },
                },
              ],
              goals: [
                {
                  active: true,
                  eventDetails: {
                    useEventValue: true,
                    eventConditions: [
                      {
                        comparisonType: 'string',
                        comparisonValue: 'string',
                        expression: 'string',
                        matchType: 'string',
                        type: 'string',
                      },
                    ],
                  },
                  name: 'string',
                  type: 'string',
                  urlDestinationDetails: {
                    caseSensitive: true,
                    firstStepRequired: true,
                    matchType: 'string',
                    steps: [
                      {
                        '1': 1,
                        name: 'string',
                        url: 'string',
                      },
                    ],
                    url: 'string',
                  },
                  value: 'string',
                  visitNumPagesDetails: {
                    comparisonType: 'string',
                    comparisonValue: 'string',
                  },
                  visitTimeOnSiteDetails: {
                    comparisonType: 'string',
                    comparisonValue: 'string',
                  },
                },
              ],
              googleAdsLinked: {
                value: true,
                status: 'ok',
                notes: 'googleAdsLinked notes wow',
                views: [
                  {
                    viewId: 'viewId1',
                    status: 'missing',
                  },
                  {
                    viewId: 'viewId2',
                    status: 'ok',
                  },
                ],
              },
              name: 'test 4',
              notes: 'string - long',
              propertyId: 'ga012123',
              searchConsoleLinked: {
                value: true,
                status: 'ok',
                notes: 'searchConsoleLinked notes wow',
                views: [
                  {
                    viewId: 'viewId1',
                    status: 'ok',
                  },
                  {
                    viewId: 'viewId2',
                    status: 'ok',
                  },
                ],
              },
              status: {
                missing: 2,
                ok: 2,
                needsUpdate: 0,
              },
              views: [
                {
                  active: true,
                  botFilteringEnabled: {
                    value: true,
                    status: 'ok',
                    notes: 'Super Unique Note 3',
                  },
                  defaultPage: {
                    value: 'string',
                    status: 'missing',
                    notes: 'Super Unique Note 2',
                  },
                  designation: {
                    value: 'string',
                    status: 'missing',
                    notes: 'Super Unique Note 1',
                  },
                  excludeQueryParameters: {
                    value: 'string',
                    status: 'missing',
                    notes: 'Super Unique Note 4',
                  },
                  name: 'Super View',
                  notes:
                    'This is one of the best views of all time; Picture an evergreen forest, the trees covered with moss and surrounded by tall ferns.',
                  siteSearchCategoryParameters: {
                    value: 'string',
                    status: 'missing',
                    notes: 'Super Unique Note 7',
                  },
                  siteSearchQueryParameters: {
                    value: 'string',
                    status: 'needsUpdate',
                    notes: 'Super Unique Note 5',
                  },
                  status: {
                    missing: 4,
                    ok: 3,
                    needsUpdate: 1,
                  },
                  stripSiteSearchCategoryParameters: {
                    value: true,
                    status: 'ok',
                    notes: 'Super Unique Note 8',
                  },
                  stripSiteSearchQueryParameters: {
                    value: true,
                    status: 'ok',
                    notes: 'Super Unique Note 6',
                  },
                  viewId: 'viewId1',
                },
                {
                  active: true,
                  botFilteringEnabled: {
                    value: true,
                    status: 'ok',
                    notes: 'Super Unique Note 3',
                  },
                  defaultPage: {
                    value: 'string',
                    status: 'missing',
                    notes: 'Super Unique Note 2',
                  },
                  designation: {
                    value: 'string',
                    status: 'missing',
                    notes: 'Super Unique Note 1',
                  },
                  excludeQueryParameters: {
                    value: 'string',
                    status: 'missing',
                    notes: 'Super Unique Note 4',
                  },
                  name: 'Awesome View',
                  notes: 'string',
                  siteSearchCategoryParameters: {
                    value: 'string',
                    status: 'missing',
                    notes: 'Super Unique Note 7',
                  },
                  siteSearchQueryParameters: {
                    value: 'string',
                    status: 'needsUpdate',
                    notes: 'Super Unique Note 5',
                  },
                  status: {
                    missing: 4,
                    ok: 3,
                    needsUpdate: 1,
                  },
                  stripSiteSearchCategoryParameters: {
                    value: true,
                    status: 'ok',
                    notes: 'Super Unique Note 8',
                  },
                  stripSiteSearchQueryParameters: {
                    value: true,
                    status: 'ok',
                    notes: 'Super Unique Note 6',
                  },
                  viewId: 'viewId2',
                },
              ],
            },
          ],
          active: true,
        },
      ],
    },
    id: 1,
    integrityConfiguration: {
      createdBy: 1,
      createdTimestamp: 'string',
      tests: [
        {
          createdBy: 1,
          createdTimestamp: 'string',
          enabled: true,
          name: 'string',
          note: 'string',
          status: 'ok',
          updatedBy: 1,
          updatedTimestamp: 'string',
        },
      ],
      updatedBy: 1,
      updatedTimestamp: 'string',
    },
    name: 'Wheelhouse',
    status: {
      missing: 4,
      ok: 4,
      needsUpdate: 0,
    },
    updatedTimestamp: 'string',
  },
];
