import { useQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Header, Form } from 'semantic-ui-react';
import { ErrorSegment, LoadingSegment } from '../../../components';
import { INSERT_CLIENT_MODEL } from '../../../graphql/mutations';
import { GET_CURRENT_CLIENT } from '../../../graphql/queries';
import GoogleAnalytics from './GoogleAnalytics';

const ModelBuilder = ({ exit }) => {
  const [state, setState] = useState<{
    conversionType?: string;
    dataSource?: string;
    gaAccount?: string;
    gaProperty?: string;
    modelDescription?: string;
    modelName?: string;
  }>({
    // 	show: false,
    // 	activeItem: 'model',
  });
  const history = useHistory();
  const { loading, error, data } = useQuery(GET_CURRENT_CLIENT);
  const [addClientModel, { data: mutationData, error: mutationError, loading: mutationLoading }] =
    useMutation(INSERT_CLIENT_MODEL);

  const handleChange = (e, data) => setState({ ...state, [data.name]: data.value });

  const handleSubmit = () => {
    addClientModel({
      variables: {
        name: state.modelName,
        description: state.modelDescription || '',
        client_id: data.client_user[0].active_client_id,
        configuration: { ...state },
      },
    });
  };

  if (mutationData) {
    history.push(`/sextant/${mutationData.insert_sextant_client_model.returning[0].client_model_id}`);
  }
  if (loading) {
    return <LoadingSegment />;
  } else if (error || mutationError) {
    return <ErrorSegment message={JSON.stringify(error || mutationError)} />;
  }
  return (
    <>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Header as="h2">Model Builder</Header>
        </Grid.Column>
        <Grid.Column floated="right" textAlign="right">
          <Button basic negative content="Cancel" icon="trash" onClick={() => exit()} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Form onSubmit={handleSubmit} loading={mutationLoading}>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                required
                name="modelName"
                label="Model Name"
                placeholder="Ecommerce Transaction Goal 1"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.TextArea
              label="Model Description"
              placeholder="Something to remember why this was created..."
              name="modelDescription"
              onChange={handleChange}
            />
            <Header as="h3">Data Configuration</Header>
            <Form.Dropdown
              required
              selection
              name="dataSource"
              label="Data Source"
              onChange={handleChange}
              options={[
                {
                  key: 'ga',
                  value: 'ga',
                  text: 'Google Analytics',
                },
              ]}
            />
            {state.dataSource ? (
              <GoogleAnalytics
                handleChange={handleChange}
                state={state}
                clientId={data && data.client_user ? data.client_user[0].active_client_id : null}
              />
            ) : null}

            <Form.Button priamry="true" disabled={state && state.dataSource ? false : true}>
              Save & Open
            </Form.Button>
          </Form>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export default ModelBuilder;
