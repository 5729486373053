import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { Button, Form, Header, Message, Segment } from 'semantic-ui-react';
import { EmailForm, ResetResponse } from '../../types';

const ForgotPasswordForm = () => {
  const [userForgotCredentials, setUserForgotCredentials] = useState<EmailForm>({
    email: '',
  });

  const [resetResponse, setResetResponse] = useState<ResetResponse>({
    show_message: false,
    error: false,
    message: '',
  });

  //Left todo: Finish Reset Lifecycle
  const requestPasswordReset = (emailForm) => {
    //TODO: Add client validation
    Auth.forgotPassword(emailForm.email)
      .then((data) => {
        if (typeof data.CodeDeliveryDetails !== 'undefined') {
          setResetResponse({
            show_message: true,
            error: false,
            message: 'Your reset code and link has been sent to this email address',
          });
        }
      })
      .catch(() => {
        setResetResponse({
          show_message: true,
          error: true,
          message: 'We could not find the user specified. Please reach out to Navigator support.',
        });
      });
  };

  return (
    <div>
      <Header as="h2" textAlign="center" style={{ color: '#808080' }}>
        Request Password Reset Code:
      </Header>
      {resetResponse.show_message && resetResponse.error && <Message negative>{resetResponse.message}</Message>}
      {resetResponse.show_message && !resetResponse.error && <Message positive>{resetResponse.message}</Message>}
      <Form
        size="large"
        onSubmit={() => {
          requestPasswordReset(userForgotCredentials);
        }}
      >
        <Segment stacked>
          <Form.Input
            fluid
            icon="mail"
            iconPosition="left"
            value={userForgotCredentials.email}
            onChange={(e, { value }) => setUserForgotCredentials({ email: value })}
            placeholder="My Email"
          />
          <Button color="teal" fluid size="large">
            Send Reset Code
          </Button>
        </Segment>
      </Form>
    </div>
  );
};

export default ForgotPasswordForm;
