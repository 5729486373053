import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledContainer = styled(Grid)`
  height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
`;

const StyledColumn = styled(Grid.Column)`
  margin: 0 0 0 100px !important;
  padding: 0 !important;
  height: 100% !important;
`;

const ColumnWrapper = styled(Grid.Column)`
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: 100px !important;
  min-width: 100px !important;
  padding: 0 !important;
  position: fixed !important;
  z-index: 1000 !important;
`;

const RowWrapper = styled(Grid.Row)`
  padding: 0 !important;
  margin: 0 !important;
  height: 100% !important;
`;

const StyledLogo = styled.div`
  width: 100% !important;
  margin: 50px 0 0 0 !important;
  text-align: center !important;
`;

export { ColumnWrapper, RowWrapper, StyledLogo, StyledContainer, StyledColumn };
