import { gql } from '@apollo/client';

export const GET_ACTIVE_USER = gql`
  query GetActiveClient {
    client_user {
      active_client_id
    }
  }
`;

export const GET_CLIENT_BY_UUID = gql`
  query ClientData($client_id: uuid!) {
    client_client_by_pk(client_id: $client_id) {
      client_id
      name
      description
      google_ads_client_id
      updated
    }
  }
`;

export const GET_ACTIVE_TESTS = gql`
  query GetActiveTests($client_id: uuid!, $_eq: uuid = "") {
    client_client_by_pk(client_id: $client_id) {
      client_client_test(where: { test: { enabled: { _eq: true } } }) {
        test {
          test_id
          name
          description
          test_status
          last_executed
          test_type
          resolved_state
        }
      }
    }
  }
`;

export const GET_INACTIVE_TESTS = gql`
  query GetActiveTests($client_id: uuid!, $_eq: uuid = "") {
    client_client_by_pk(client_id: $client_id) {
      client_client_test(where: { test: { enabled: { _eq: false } } }) {
        test {
          test_id
          name
          description
          test_status
          last_executed
          test_type
          resolved_state
        }
      }
    }
  }
`;

export const GET_TEST_TEMPLATES = gql`
  query getTestTemplates {
    sonar_test_template {
      test_template_id
      name
      description
      enabled
      log_path
      resolved_state
      network_capture_config
      start_url
      updated
      test_type
      test_status
      log_bucket
      cron_schedule
      thumbnail_uri
      step_templates {
        step_template_id
        test_template_id
        name
        type
        action_type
        config
        sequence
        updated
      }
      assertion_templates {
        assertion_template_id
        test_template_id
        name
        type
        config
        updated
      }
    }
  }
`;

export const GET_CLIENTS = gql`
  query GetClients {
    client_client {
      client_id
      name
      description
      client_client_test_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_ESCALATIONS = gql`
  query GetEscalations($escalation_id: uuid!) {
    sonar_escalation(where: { escalation_id: { _eq: $escalation_id } }) {
      escalation_id
      name
      config
      type
      ga_ads_cid
      ga_campaigns {
        id
        name
        resource_name
      }
      updated
    }
  }
`;
