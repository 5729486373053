import { useQuery, gql, useMutation } from '@apollo/client';
import ace from 'brace';
import { deepParseJson } from 'deep-parse-json';
import { JsonEditor as Editor } from 'jsoneditor-react';
import { useState } from 'react';
import { Form, Input, Button, Confirm, Icon, Accordion, Select } from 'semantic-ui-react';
import 'jsoneditor-react/es/editor.min.css';

import 'brace/mode/json';
import 'brace/theme/solarized_dark';

const GET_ASSERTIONS = gql`
  query GetAssertions($assertion_id: uuid!) {
    sonar_assertion(where: { assertion_id: { _eq: $assertion_id } }) {
      assertion_id
      name
      type
      config
      updated
    }
  }
`;

const UPDATE_ASSERTION = gql`
  mutation UpdateAssertion(
    $assertion_id: uuid!
    $config: jsonb!
    $name: String!
    $type: String!
    $updated: timestamp!
  ) {
    update_sonar_assertion_by_pk(
      pk_columns: { assertion_id: $assertion_id }
      _set: { name: $name, type: $type, config: $config, updated: $updated }
    ) {
      assertion_id
      name
      type
      config
      updated
    }
  }
`;

const DELETE_ASSERTION = gql`
  mutation DeleteAssertion($assertion_id: uuid = "") {
    delete_sonar_assertion(where: { assertion_id: { _eq: $assertion_id } }) {
      affected_rows
      returning {
        assertion_id
      }
    }
  }
`;

//Types
type Accordian = {
  activeIndex: number;
};

type Assertion = {
  assertion_id: string;
  config: string;
  name: string;
  type: string;
};

const assertionTypes = [
  { key: 'equal', text: 'Equals (Case Sensative)', value: 'EQUAL' },
  {
    key: 'not_equal',
    text: 'Does not Equal (Case Sensative)',
    value: 'NOT_EQUAL',
  },
  {
    key: 'step_compare',
    text: 'Compare Step Return Values',
    value: 'STEP_COMPARE',
  },
  {
    key: 'contains_regex',
    text: 'Matches a RegEx Pattern',
    value: 'CONTAINS_REGEX',
  },
];

type Props = {
  assertion_id: string;
  index: number;
};

interface confirmDialog {
  open: boolean;
  result: string;
}

const AssertionForm = ({ assertion_id, index }: Props) => {
  const [accordianState, setAccordianState] = useState<Accordian>({
    activeIndex: -1,
  });

  const [confirmState, setConfirmState] = useState<confirmDialog>({
    open: false,
    result: '',
  });

  //Setup Test State
  const [assertionState, setAssertionState] = useState<Assertion>();

  //Get Test Apollo Query
  const {
    loading: queryLoading,
    error: queryError,
    data,
  } = useQuery(GET_ASSERTIONS, {
    variables: { assertion_id },
  });

  //Update Step
  const [updateAssertion, { loading: mutationLoading, error: mutationError }] = useMutation(UPDATE_ASSERTION);

  //Delete Step
  const [deleteAssertion, { loading: mutationDeleteLoading, error: mutationDeleteError }] = useMutation(
    DELETE_ASSERTION,
    {
      onCompleted: (data) => {
        if (data.delete_sonar_assertion.affected_rows) {
          //Brute force for now :P
          window.location.reload();
        }
      },
    },
  );

  if (queryLoading) {
    return <p>Loading...</p>;
  }
  if (queryError) {
    return <p>Error :(</p>;
  }

  const handleAccordianClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = accordianState;
    const newIndex = activeIndex === index ? -1 : index;

    setAccordianState({ activeIndex: newIndex });
  };

  const handleDelete = () => {
    deleteAssertion({
      variables: { assertion_id: assertionState?.assertion_id },
    });
  };

  const handleCancel = (e, value) => {
    setConfirmState({ open: false, result: value });
  };

  const { activeIndex } = accordianState;

  // eslint-disable-next-line array-callback-return
  data.sonar_assertion.map((assertion: Assertion) => {
    if (!assertionState) {
      setAssertionState(assertion);
    }
  });

  if (!assertionState) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <Accordion.Title active={activeIndex === index} index={index} onClick={handleAccordianClick}>
        <Icon name="dropdown" />
        {assertionState.name}
      </Accordion.Title>
      <Accordion.Content active={activeIndex === index}>
        <Form
          onSubmit={(e) => {
            const updated = new Date();
            e.preventDefault();
            updateAssertion({
              variables: {
                assertion_id: assertionState.assertion_id,
                config: assertionState.config,
                name: assertionState.name,
                type: assertionState.type,
                updated,
              },
            });
          }}
        >
          <Form.Group widths="equal">
            <Form.Field
              id="form-input-control-test-assertion-name"
              control={Input}
              value={assertionState.name}
              label="Assertion Name"
              placeholder="Step name"
              onChange={(e, { value }) => setAssertionState({ ...assertionState, name: value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              id="form-input-control-test-assertion-type"
              control={Select}
              label="Assertion Type"
              value={assertionState.type}
              options={assertionTypes}
              onChange={(e, { value }) => setAssertionState({ ...assertionState, type: value })}
            />
          </Form.Group>
          <Editor
            id="form-textarea-control-test-assertion-config"
            value={deepParseJson(assertionState.config)}
            mode="code"
            ace={ace}
            theme="ace/theme/solarized_dark"
            onChange={(json) => {
              if (json) {
                setAssertionState({
                  assertion_id: assertionState.assertion_id,
                  name: assertionState.name,
                  type: assertionState.type,
                  config: JSON.stringify(json),
                });
              }
            }}
          />
          <p></p>
          <Button primary type="submit">
            Save Assertion
          </Button>
          <Button
            color="red"
            onClick={() => {
              setConfirmState({ ...confirmState, open: true });
            }}
          >
            Delete Assertion
          </Button>
          <Confirm
            open={confirmState.open}
            content="Permanently delete this Assertion?"
            onCancel={handleCancel}
            onConfirm={handleDelete}
            negative
          />
          {mutationLoading && <p>Loading...</p>}
          {mutationError && <p>Error :( Please try again</p>}
          {mutationDeleteLoading && <p>Loading...</p>}
          {mutationDeleteError && <p>Error :( Please try again</p>}
        </Form>
      </Accordion.Content>
    </div>
  );
};

export default AssertionForm;
