import { useQuery, useMutation } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { Message, Grid, Form, Input, Header, Button } from 'semantic-ui-react';
import { ErrorSegment, LoadingSegment } from '../../components';
import { SET_PROFILE } from '../../graphql/mutations';
import { GET_PROFILE } from '../../graphql/queries';

type State = {
  confirm_new_password?: string;
  family_name?: string;
  given_name?: string;
  new_password?: string;
  old_password?: string;
  passwordError?: string;
  passwordLoading?: boolean;
};

const ProfileSettings = () => {
  const [state, setState] = useState<State>({
    passwordLoading: false,
  });
  const { loading, error, data } = useQuery(GET_PROFILE);
  const [setProfile, { loading: mutationLoading, error: mutationError }] = useMutation(SET_PROFILE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_PROFILE }],
  });

  if (loading) {
    return <LoadingSegment />;
  } else if (error || mutationError) {
    return <ErrorSegment message={JSON.stringify(error || mutationError)} />;
  }
  if (data && data.client_user.length === 0) {
    return <ErrorSegment message={'No User Available'} />;
  }

  const profile = data.client_user[0];

  const handleChange = (e, { name, value }) => {
    setState({ ...state, [name]: value });
  };

  const handleProfileSubmit = () => {
    const { given_name, family_name } = state;
    const payload: State = {};
    payload.given_name =
      given_name && given_name !== '' && given_name !== profile.given_name ? given_name : profile.given_name;
    payload.family_name =
      family_name && family_name !== '' && family_name !== profile.family_name ? family_name : profile.family_name;
    if (profile.family_name !== payload.family_name || profile.given_name !== payload.given_name) {
      setProfile({ variables: payload });
    }
  };

  const handlePasswordSubmit = () => {
    if (state.old_password && state.old_password.length > 0) {
      if (state.new_password === state.confirm_new_password) {
        if (state.new_password.length >= 12) {
          setState({
            ...state,
            passwordError: undefined,
            passwordLoading: true,
          });
          Auth.currentAuthenticatedUser()
            .then((user) => {
              return Auth.changePassword(user, state.old_password, state.new_password);
            })
            .then(() => setState({ ...state, passwordLoading: false }))
            .catch((err) =>
              setState({
                ...state,
                passwordLoading: false,
                passwordError: err.message,
              }),
            );
        } else {
          setState({
            ...state,
            passwordError: 'New Password Must be 12 characters or longer',
          });
        }
      } else {
        setState({ ...state, passwordError: 'New Passwords Do Not Match' });
      }
    } else {
      setState({
        ...state,
        passwordError: 'Please Enter your Current Password',
      });
    }
  };

  return (
    <Grid container>
      <Grid.Row>
        <Grid.Column>
          <Header as="h2">Profile</Header>
          <Form onSubmit={handleProfileSubmit} loading={mutationLoading}>
            <Form.Group widths="equal">
              <Form.Field
                name="given_name"
                control={Input}
                label="First name"
                placeholder={profile.given_name || 'First name'}
                defaultValue={profile.given_name}
                onChange={handleChange}
              />
              <Form.Field
                name="family_name"
                control={Input}
                label="Last name"
                placeholder={profile.family_name || 'Last name'}
                defaultValue={profile.family_name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Field control={Input} readOnly label="Email" value={profile.cognito_email} />
            <Form.Field
              control={Button}
              content="Confirm"
              disabled={state.family_name || state.given_name ? false : true}
            />
          </Form>
          <Header as="h2">Password</Header>
          <Form
            error={state.passwordError ? true : false}
            onSubmit={handlePasswordSubmit}
            loading={state.passwordLoading}
          >
            {state.passwordError ? <Message error header="Form Error" content={state.passwordError} /> : null}
            <Form.Field
              control={Input}
              label="Current Password"
              name="old_password"
              onChange={handleChange}
              type="password"
              required
            />
            <Form.Group widths="equal">
              <Form.Field
                control={Input}
                label="New Password"
                name="new_password"
                onChange={handleChange}
                error={
                  state.new_password && state.new_password.length < 12
                    ? 'Password must be 12 characters or longer'
                    : null
                }
                type="password"
                required
              />
              <Form.Field
                control={Input}
                label="Confirm New Password"
                name="confirm_new_password"
                onChange={handleChange}
                error={
                  state.new_password && state.confirm_new_password && state.new_password !== state.confirm_new_password
                    ? 'Passwords must match'
                    : null
                }
                type="password"
                required
              />
            </Form.Group>
            <Form.Field
              control={Button}
              content="Confirm"
              disabled={state.old_password && state.new_password && state.confirm_new_password ? false : true}
            />
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ProfileSettings;
