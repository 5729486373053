export const SET_THEME = 'SET_THEME';
export const SET_CLIENT = 'SET_CLIENT';
export const SET_PROPERTY = 'SET_PROPERTY';
export const SET_PROPERTY_TAB = 'SET_PROPERTY_TAB';
export const SET_PROPERTY_TAB_ITEM = 'SET_PROPERTY_TAB_ITEM';
export const SET_PROPERTY_TAB_SUB_ITEM = 'SET_PROPERTY_TAB_SUB_ITEM';
export const SET_PROPERTY_VIEW = 'SET_PROPERTY_VIEW';
export const SET_PROPERTY_VIEW_TAB = 'SET_PROPERTY_VIEW_TAB';
export const SET_PROPERTY_VIEW_TAB_ITEM = 'SET_PROPERTY_VIEW_TAB_ITEM';
export const GET_CLIENT = 'GET_CLIENT';
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';

export interface UserThemeAction {
  payload: string;
  type: typeof SET_THEME;
}

export interface UserClientAction {
  payload: string | null;
  type: typeof SET_CLIENT;
}

export interface UserPropertyAction {
  payload: string | null;
  type: typeof SET_PROPERTY;
}

export interface UserPropertyTabAction {
  payload: string | null;
  type: typeof SET_PROPERTY_TAB;
}

export interface UserPropertyTabItemAction {
  payload: string | null;
  type: typeof SET_PROPERTY_TAB_ITEM;
}

export interface UserPropertyTabSubItemAction {
  payload: string | null;
  type: typeof SET_PROPERTY_TAB_SUB_ITEM;
}

export interface UserPropertyViewAction {
  payload: string | null;
  type: typeof SET_PROPERTY_VIEW;
}

export interface UserPropertyViewTabAction {
  payload: string | null;
  type: typeof SET_PROPERTY_VIEW_TAB;
}

export interface UserPropertyViewTabItemAction {
  payload: string | null;
  type: typeof SET_PROPERTY_VIEW_TAB_ITEM;
}

export type UserAction =
  | UserThemeAction
  | UserClientAction
  | UserPropertyAction
  | UserPropertyTabAction
  | UserPropertyViewAction
  | UserPropertyViewTabAction
  | UserPropertyViewTabItemAction
  | UserPropertyTabItemAction
  | UserPropertyTabSubItemAction;

export interface UserState {
  client: string | null;
  property: string | null;
  propertyTab: string | null;
  propertyTabItem: string | null;
  propertyTabSubItem: string | null;
  propertyView: string | null;
  propertyViewTab: string | null;
  propertyViewTabItem: string | null;
  theme: string;
  toolView: string | null;
}

export type ViewType = {
  name?: string;
  status?: string;
  viewId?: string;
};

export interface Client {
  active: boolean;
  createdTimestamp: string;
  description: string;
  googleAnalytics: {
    accounts: [
      {
        accountId: string;
        active: boolean;
        properties: [
          {
            currency: string;
            // 1
            customDimensions: [
              // 4
              {
                notes: string;
                status: string;
                value: {
                  active: boolean;
                  dc: boolean;
                  index: string;
                  name: string;
                  scope: string;
                };
                views: ViewType[];
              },
            ];
            customMetrics: [
              // 5
              {
                notes: string;
                status: string;
                value: {
                  active: boolean;
                  index: number;
                  max_value: string;
                  min_value: string;
                  name: string;
                  scope: string;
                  type: string;
                };
                views: ViewType[];
              },
            ];
            // view currency (example: USD)
            eCommerceTracking: {
              notes: string;
              status: string;
              value: boolean;
              views: ViewType[];
            };
            enhancedECommerceTracking: {
              notes: string;
              status: string;
              value: boolean;
              views: ViewType[];
            };
            filters: [
              {
                advancedDetails: [
                  {
                    id: string;
                    notes: string;
                    status: string;
                    value: {
                      caseSensitive: boolean;
                      extract: string;
                      field: string;
                      fieldIndex: number;
                      fieldRequired: boolean;
                      outputConstructor: string;
                      outputToField: string;
                      outputToFieldIndex: number;
                      overrideOutputField: boolean;
                    };
                    views: ViewType[];
                  },
                ];
                created: string;
                excludeDetails: {
                  notes: string;
                  status: string;
                  value: {
                    caseSensitive: boolean;
                    expressionValue: string;
                    field: string;
                    fieldIndex: number;
                    matchType: string;
                  };
                  views: ViewType[];
                };
                id: string;
                includeDetails: {
                  notes: string;
                  status: string;
                  value: {
                    caseSensitive: boolean;
                    expressionValue: string;
                    field: string;
                    fieldIndex: number;
                    matchType: string;
                  };
                  views: ViewType[];
                };
                lowercaseDetails: {
                  notes: string;
                  status: string;
                  value: {
                    field: string;
                    fieldIndex: number;
                  };
                  views: ViewType[];
                };
                name: string;
                searchAndReplaceDetails: {
                  notes: string;
                  status: string;
                  value: {
                    caseSensitive: boolean;
                    field: string;
                    fieldIndex: number;
                    replaceString: string;
                    searchString: string;
                  };
                  views: ViewType[];
                };
                type: string;
                updated: string;
                uppercaseDetails: {
                  notes: string;
                  status: string;
                  value: {
                    field: string;
                    fieldIndex: number;
                  };
                  views: ViewType[];
                };
              },
            ];
            goals: [
              {
                active: boolean;
                eventDetails: {
                  eventConditions: [
                    {
                      comparisonType: string;
                      comparisonValue: string;
                      expression: string;
                      matchType: string;
                      type: string;
                    },
                  ];
                  useEventValue: boolean;
                };
                name: string;
                type: string;
                urlDestinationDetails: {
                  caseSensitive: boolean;
                  firstStepRequired: boolean;
                  matchType: string;
                  steps: [
                    {
                      name: string;
                      number: number;
                      url: string;
                    },
                  ];
                  url: string;
                };
                value: string;
                visitNumPagesDetails: {
                  comparisonType: string;
                  comparisonValue: string;
                };
                visitTimeOnSiteDetails: {
                  comparisonType: string;
                  comparisonValue: string;
                };
              },
            ];
            // 2
            googleAdsLinked: {
              notes: string;
              status: string;
              value: boolean;
              views: ViewType[];
            };
            name: string;
            // view name (example: www.positivepromotions.com)
            notes: string;
            propertyId: string;
            searchConsoleLinked: {
              notes: string;
              status: string;
              value: boolean;
              views: ViewType[];
            };
            status: {
              missing: number;
              needsUpdate: number;
              ok: number;
            };
            views: [
              {
                active: boolean;
                botFilteringEnabled: {
                  notes: string;
                  status: string;
                  value: boolean;
                };
                defaultPage: {
                  notes: string;
                  status: string;
                  value: string;
                };
                excludeQueryParameters: {
                  notes: string;
                  status: string;
                  value: string;
                };
                name: string;
                notes: string;
                siteSearchCategoryParameters: {
                  notes: string;
                  status: string;
                  value: string;
                };
                siteSearchQueryParameters: {
                  notes: string;
                  status: string;
                  value: string;
                };
                status: {
                  missing: number;
                  needsUpdate: number;
                  ok: number;
                };
                stripSiteSearchCategoryParameters: {
                  notes: string;
                  status: string;
                  value: boolean;
                };
                stripSiteSearchQueryParameters: {
                  notes: string;
                  status: string;
                  value: boolean;
                };
                tag: string;
                viewId: string;
              }[],
            ];
          },
        ];
      },
    ];
  };
  id: number;
  integrityConfiguration: {
    //(FK -> Users),
    createdBy: number;
    createdTimestamp: string;
    tests: [
      {
        //(FK -> Users),
        createdBy: number;
        createdTimestamp: string;
        enabled: boolean;
        name: string;
        note: string | null;
        status: {
          missing: number;
          needsUpdate: number;
          ok: number;
        };
        updatedBy: number;
        updatedTimestamp: string; //(FK -> Users),
      },
    ];
    updatedBy: number;
    //(FK -> Users),
    updatedTimestamp: string;
  } | null;
  name: string;
  status: {
    missing: number;
    needsUpdate: number;
    ok: number;
  };
  updatedTimestamp: string;
}

interface GetClientAction {
  payload: Client;
  type: typeof GET_CLIENT;
}

interface SetLoadingAction {
  type: typeof SET_LOADING;
}

interface SetErrorAction {
  payload: string;
  type: typeof SET_ERROR;
}
export interface ClientState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clients: any;
  error: string;
  loading: boolean;
}

export type ClientAction = GetClientAction | SetErrorAction | SetLoadingAction;
